import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { useNavigate,useParams } from 'react-router-dom';
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';

import FlashMessage from '../../components/FlashMessage';
import Loaders from '../../components/Loader';



// import ReactTooltip from 'react-tooltip';

// Icons & Images Files
import Icons from '../../components/icons';


const PageAffiliate = () => {
        
  //Auth related var

  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const token = state.token || '';
  const isInitialMount = useRef(true);
  

  //Loader
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitSuccessMsg, setRequestSubmitSuccessMsg] = useState('');
  
    const [referralLink, setReferralLink] = useState('');
    const [affilateData, setAffilateData] = useState('');
    
    const copyClick = (value) => {
        setRequestSubmitSuccsess(false);
        setRequestSubmitError(false);
        navigator.clipboard.writeText(value)
          .then(() => {
            setRequestSubmitSuccsess(true);
            setRequestSubmitSuccessMsg('Text copied to clipboard');
            // console.log('Text copied to clipboard');
          })
          .catch((error) => {
            setRequestSubmitError(true)
            setRequestSubmitErrorMsg('Error copying text')
            // console.error('Error copying text: ', error);
          });
      };

      // const [isAffiliate, setAffiliate] = useState(1);

      // Early redirection based on isAffiliate
      // useEffect(() => {
      //   if (isAffiliate == 0) {
      //     navigate('/home');
      //   }
      // }, [isAffiliate, navigate]);
  
    useEffect(() => {
        const fetchData = async () => {
          const loggedInUser = getLoggedInUserData();
          const isUserLoggedIn = !!loggedInUser;
          
          if (isUserLoggedIn) {
            if (loggedInUser.id && token) {
              try {
                console.log(localStorage.getItem('userData'));
                // if(isAffiliate != '0'){
                  
                  const checkAffiliate = await checkAffiliateAvailable();
                  const reffalLink = await getReferralLink();
                  const affiliteDetails = await getAffilateDetails();
                  setDataLoaded(true);
                  // console.log(tradeHistory);
                  // Assuming getUserWalletGroupWise returns the data needed for wallets and walletGroups
                // }
              } catch (error) {
                navigate('/login');
                console.error('Failed to fetch ib data:', error);
              }
            }
          }
        };
  
        if (isInitialMount.current) {
          isInitialMount.current = false;
          fetchData();
        }
    }, [token]);

    const [isAffiliate, setAffiliate] = useState(1);
    useEffect(() => {
      if(isAffiliate != 1){
        navigate('/home');
      }
    },[isAffiliate])

     const checkAffiliateAvailable = async () => {
        try {
          // setIsLoading(true);
          // console.log({id});
          const response = await apiCall(API_URL +'check-affiliate-available', {
              user_id: state.userData.id,
              token: token
          });
          if (response.data.success == '0') {
            // console.log(response.data.data);
            setAffiliate(response.data.data);
            // setTotalPagesDailySummary(response.data.data.last_page)
          }
          // setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
      };

    const getReferralLink = async () => {
        try {
          setIsLoading(true);
          // console.log({id});
          const response = await apiCall(API_URL +'get-referral-link', {
              user_id: state.userData.id,
              token: token,
          });
          if (response.data.success == '0') {
            //   console.log('Affilite refreeal ', response.data.data);
              setReferralLink(response.data.data.referral_link);
            //   setTotalPagesTradingHistory(response.data.data.last_page)
          }
          setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    const getAffilateDetails = async () => {
        try {
          setIsLoading(true);
          // console.log({id});
          const response = await apiCall(API_URL +'get-affilate-details', {
              user_id: state.userData.id,
              token: token,
          });
          if (response.data.success == '0') {
              console.log('Affilite refreeal ', response.data.data);
              setAffilateData(response.data.data);
          }
          setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleToNavigate = () => { 
        navigate('/payouts')
    }
  return (
    <>
     {!dataLoaded && <Loaders/>}
         <div className="custome-wrapper">
             <div className='container-lg cl-custome3'>
                 <div className='row row-gap-4'>
                    <div className="col-12">
                        <div className="common-box main-affiliate-section">
                            <div className="common-box-heading cbh-small"><Icons.UsersSvg />Affiliate</div>

                            <div className="common-box mas-inner darkgray mb-4">
                                <div className="payout-request-btn py-0">
                                    <div className="prb-inner">
                                        <div class="common-box-heading">Your Referral Code</div>
                                        <div class="common-box-sub-heading pt-1">Use this URL to promote across different platforms and track your conversions on the referrals tab, this link is unique to you.</div>
                                    </div>
                                      <div class="common-btn-item cbi-fill"><div className='mas-link pe-1'>{referralLink}</div> <span onClick={() => copyClick(referralLink)}><Icons.CopySvg /></span></div>
                                </div>
                            </div>

                            <div className="common-box mas-aifiliate-work mb-4">
                                <div className="notifaction-alert-box">
                                    <div className="nab-left">
                                        <span className="nab-alert"><Icons.ExclamationCircleSvg />Click on the link to know more about how affiliates work</span>
                                    </div> 
                                    <div className="nab-right">
                                        <Link to='https://www.fundedfirm.com/affiliate-program' class="common-btn-item cbi-fill" target="_blank"><span>Visit Here</span></Link>
                                    </div> 
                                </div>      
                            </div>

                            {/*Total affiliate start */}
                            <div className='common-box affiliate-box'>
                                 <div className='total-affiliate'>
                                     <div className='taf-left'>
                                        <div className='taf-section'>
                                            <div class="taf-heading">Total Purchased</div>
                                              <div class="taf-count">{affilateData.countOfUser}</div>
                                        </div> 
                                        <div className='taf-section'>
                                            <div class="taf-heading">Total Earned   </div>
                                            <div class="taf-count">${affilateData.totalEarned > 0 ? affilateData.totalEarned.toFixed(2) : 0 }</div>
                                        </div> 
                                        <div className='taf-section'>
                                            <div class="taf-heading">Total Paid Out</div>
                                              <div class="taf-count">${affilateData.totalPaidOut > 0 ? affilateData.totalPaidOut.toFixed(2) : 0}</div>
                                        </div> 

                                        <div className='payout-request'>
                                             Please use the <span onClick={handleToNavigate}>payouts</span> section to request a payout  
                                        </div>
                                     </div>
                                     {(affilateData.referralCodeUserFind && affilateData.referralCodeUserFind.length > 0 && !isLoading) &&

                                        <div className='taf-right'>
                                          <div class="table-responsive custom-table-responsive calendar-table">
                                              <table class="custom-table">
                                                  <thead>
                                                      <tr>
                                                          <th>REFERRAL COMPLETED AT</th>
                                                          <th>ACCOUNT SIZE</th>
                                                          <th>INCENTIVE/FEE</th>
                                                          
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                    {affilateData.referralCodeUserFind.map((referralUser, index) => (
                                                      
                                                          <tr>
                                                          {/* Safely access the first user’s account number */}
                                                          <td>{referralUser.wallets.length > 0 ? referralUser.wallets[0].created_date_time_formattad : ''}</td>
                                                          {/* Safely access the balance of the first wallet */}
                                                          <td>{referralUser.wallets.length > 0 ? referralUser.wallets[0].account_size.limit : 0}</td>
                                                          {/* Perform calculation only if the wallet array is not empty */}
                                                          <td>
                                                              $ 
                                                              {referralUser.wallets.length > 0 
                                                                  ? (referralUser.wallets[0].account_size.limit * (affilateData.percentangeBaseOnCountUser / 100)).toFixed(2)
                                                                  : 0}
                                                          </td>
                                                      </tr> 
                                                    ))}
                                                  </tbody>
                                              </table>
                                          </div>
                                        </div>
                                      } 
                                 </div>
                            </div>
                            {/*Total affiliate start */}

                        </div>
                        
                    </div> 
                   
                 </div>
             </div>
         </div>
         <FlashMessage type="success" isVisible={requestSubmitSuccess} message={requestSubmitSuccessMsg} />
         <FlashMessage type="error" isVisible={requestSubmitError} message={requestSubmitErrorMsg} />
      </>
  )
}

export default PageAffiliate