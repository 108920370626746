
import React, { useState, useRef, useEffect } from "react";
import { Link } from 'react-router-dom';

import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { useNavigate,useParams } from 'react-router-dom';
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';
import FlashMessage from '../../components/FlashMessage';
import Button from 'react-bootstrap/Button';
import EmptyBox from '../../components/EmptyBox'; 

// React Bootstrap
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Components File
import AccountChart from '../ComomonChart/AccountChart';
import LimitCardItem from './LimitCardItem';
import ScaleOverviewItem from './ScaleOverviewItem';

// Icons & Images Files
import Icons from '../../components/icons';
import master from "../../img/icons/account/master.png"
import practitioner from "../../img/icons/account/practitioner.png"
import student from "../../img/icons/account/student.png"
import Loaders from '../../components/Loader';
// datepaicker summary
import { format, addMonths, subMonths, startOfMonth, endOfMonth, startOfWeek, endOfWeek, addDays, isSameMonth, isSameDay ,isNextDay } from 'date-fns';
import { Icon } from "@mui/material";
import { getDaysInYear } from "date-fns/fp/getDaysInYear";

export default function PageAccountDetail() {

  //Auth related var

  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const token = state.token || '';
  const isInitialMount = useRef(true);

  const { id } = useParams();

  //Loader
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitSuccessMsg, setRequestSubmitSuccessMsg] = useState(false);

  // ---
  const [isCredentialsModal, setIsCredentialsModal] = useState(false);
  const [keyword, setKeyword] = useState('');
  
  
  // trading history table var
  const [tradingHistoryList, setTradingHistoryList] = useState([]);
  const [pageTradingHistory,setPageTradingHistory] = useState(1);
  const [goPageTradingHistory, setGoPageTradingHistory] = useState(null);
  const [totalPagesTradingHistory,setTotalPagesTradingHistory] = useState(0);
  const [sortOrderTradingHistory, setSortOrderTradingHistory] = useState('asc');
  const [sortChangeTradingHistory, setSortChangeTradingHistory] = useState(null);

  const onChangePageTradingHistory = (pageTradingHistory) => {
    // console.log('pageTradingHistory', pageTradingHistory.target.value);
    if(pageTradingHistory.target.value == ""){
      pageTradingHistory.target.value = 1;
    }
    console.log('totalPagesTradingHistory', totalPagesTradingHistory);
    if(pageTradingHistory.target.value < parseInt(totalPagesTradingHistory)){
        setPageTradingHistory(pageTradingHistory.target.value);
        setGoPageTradingHistory(pageTradingHistory.target.value);
    } else {
        setPageTradingHistory(totalPagesTradingHistory);
        setGoPageTradingHistory(totalPagesTradingHistory);
    }
  }
  const changePrevPageTradingHistory = () => {
      if(pageTradingHistory > 1){
        setPageTradingHistory(pageTradingHistory-1);
        setGoPageTradingHistory(pageTradingHistory - 1);

      }

  }
  const changeNextPageTradingHistory = () => {
    //console.log(pageTradingHistory);
    //console.log(totalPagesTradingHistory);
      if(pageTradingHistory < totalPagesTradingHistory){
          setPageTradingHistory(pageTradingHistory+1);
          setGoPageTradingHistory(pageTradingHistory + 1);

      }
  }
  // open trading table var
  const [openTradingList, setOpenTradingList] = useState([]);
  const [pageOpenTrading,setPageOpenTrading] = useState(1);
  const [goPageOpenTrading, setGoPageOpenTrading] = useState(null);
  const [totalPagesOpenTrading,setTotalPagesOpenTrading] = useState(0);
  const [sortOrderOpenTrading, setSortOrderOpenTrading] = useState('asc');
  const [sortChangeOpenTrading, setSortChangeOpenTrading] = useState(null);

  const onChangePageOpenTrading = (pageOpenTrading) => {
    console.log('on change page trading',totalPagesOpenTrading);
    console.log('on change page trading',pageOpenTrading);
    if(pageOpenTrading.target.value == ""){
      pageOpenTrading.target.value = 1;
    }
    if(pageOpenTrading.target.value < parseInt(totalPagesOpenTrading)){
        setPageOpenTrading(pageOpenTrading.target.value);
        setGoPageOpenTrading(pageOpenTrading.target.value);
    } else {
        setPageOpenTrading(totalPagesOpenTrading);
        setGoPageOpenTrading(totalPagesOpenTrading);
    }
  }
  const changePrevPageOpenTrading = () => {
      if(pageOpenTrading > 1){
        setPageOpenTrading(pageOpenTrading-1);
      }

  }
  const changeNextPageOpenTrading = () => {
    //console.log(pageOpenTrading);
    //console.log(totalPagesOpenTrading);
      if(pageOpenTrading < totalPagesOpenTrading){
          setPageOpenTrading(pageOpenTrading+1);
      }
  }

  // Daily summary trading table var
  const [dailySummaryTradingList, setDailySummaryTradingList] = useState([]);
  const [pageDailySummary,setPageDailySummary] = useState(1);
  const [goPageDailySummary, setGoPageDailySummary] = useState(null);
  const [totalPagesDailySummary,setTotalPagesDailySummary] = useState(0);
  const [sortOrderDailySummary, setSortOrderDailySummary] = useState('asc');
  const [sortChangeDailySummary, setSortChangeDailySummary] = useState(null);

  const onChangePageDailySummary = (pageDailySummary) => {
    // console.log('on change page trading',totalPagesDailySummary);
    // console.log('on change page trading',pageDailySummary);
    if(pageDailySummary.target.value == ""){
      pageDailySummary.target.value = 1;
    }
    if(pageDailySummary.target.value < parseInt(totalPagesDailySummary)){
        setPageDailySummary(pageDailySummary.target.value);
        setGoPageDailySummary(pageDailySummary.target.value);
    } else {
        setPageDailySummary(totalPagesDailySummary);
        setGoPageDailySummary(totalPagesDailySummary);
    }
  }
  const changePrevPageDailySummary = () => {
      if(pageDailySummary > 1){
        setPageDailySummary(pageDailySummary-1);
      }

  }
  const changeNextPageDailySummary = () => {
    console.log(pageDailySummary);
    console.log(totalPagesDailySummary);
      if(pageDailySummary < totalPagesDailySummary){
          setPageDailySummary(pageDailySummary+1);
      }
  }
  
  // Other info var
  const[accountNumber,setAccountNumber] = useState('');
  const[createDate,setCreateDate] = useState('');
  const[accountStatus,setAccountStatus] = useState('');
  const[accountType,setAccountType] = useState('');
  const[accountPhase, setAccountPhase] = useState('');
  
  const[accountBalance,setAccountBalance] = useState(0);
  const [accountSize, setAccountSize] = useState(0);
  const [accountEquity, setAccountEquity] = useState(0);
  const [email, setEmail] = useState('');
  const [masterPassword, setMasterPassword] = useState('');
  const [startTradePeriod, setStartTradePeriod] = useState('');
  const [endTradePeriod, setEndTradePeriod] = useState('');
  const [avgWin, setAvgWin] = useState(0);
  const [avgLoss, setAvgLoss] = useState(0);



  // analysis var
  const [numberOfDays,setNumberOfDays] = useState(0);
  const [totalTradesTaken,setTotalTradesTaken] = useState(0);
  const [avgTradesPerDay,setAvgTradesPerDay] = useState(0);
  const [totalLotsUsed,setTotalLotsUsed] = useState(0);
  const [avgLotsUsed,setAvgLotsUsed] = useState(0);
  const [biggestWin,setBiggestWin] = useState(0);
  const [biggestLoss,setbiggestLoss] = useState(0);
  const [positiveDay,setPositiveDay] = useState(0);
  const [negativeDay,setNegativeDay] = useState(0);
  const [highestRecordeduity,setHighestRecordequity] = useState(0);
  const [avgPositiveDays,setAvgPositiveDays] = useState(0);
  const [avgNegativeDays,setAvgNegativeDays] = useState(0);
  

  const [dailyMaxLossLimit, setDailyMaxLossLimit] = useState(0);
  const [maxOverallLossLimit, setMaxOverallLossLimit] = useState(0);
  const [profitTarget, setProfileTarget] = useState(0);
  const [dailyMaxThreadShold, setDailyMaxThreadShold] = useState(0);
  const [maxOverallLossThreadShold, setMaxOverallLossThreadShold] = useState(0);
  const [makeOverAllLossPercentage, setMakeOverAllLossPercentage] = useState(0);
  const [makeDailyLossPercentage, setMakeDailyLossPercentage] = useState(0);
  const [makeOverAllProfitPercentage, setMakeOverAllProfitPercentage] = useState(0);
  const [maxTradingDays, setMaxTradingDays] = useState(0);
  const [countMaxTradingDaysLeft, setCountMaxTradingDaysLeft] = useState(0);
  const [countMaxTradingDaysLeftPercentage, setCountMaxTradingDaysLeftPercentage] = useState(0);
  const [minBalance, setMinBalance] = useState(0);
  const [maxBalance, setMaxBalance] = useState(0);
  const [minEquity, setMinEquity] = useState(0);
  const [maxEquity, setMaxEquity] = useState(0);
  const [remainDailyLoss, setRemainDailyLoss] = useState(0);
  const [remainMaxLoss, setRemainMaxLoss] = useState(0);
  const [remainMaxProfit, setRemainMaxProfit] = useState(0);
  const [totalLoss, setTotalLoss] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [winRatio, setWinRatio] = useState(0);
  const [checkRealAccountIsCreated, setCheckRealAccountIsCreated] = useState(0);
  
  const [applyForRealAccount, setApplyForRealAccount] = useState(0);
  
  const [filterValue, setFilterValue] = useState('today');

  
  const [showCustomFilter, setShowCustomFilter] = useState(false);


  const [profitChartData, setProfitChartData] = useState([]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());


  const handleButtonFilterChartClick = (event) => {
    const buttons = document.querySelectorAll('.dashbord-filter button');
    buttons.forEach((button) => button.classList.remove('active'));
    event.target.classList.add('active');

    // Show custom filter only if "Custom" button is clicked
    if (event.target.value === 'custom') {
      // console.log(startDate);
      // console.log(endDate);
      setFilterValue(event.target.value);
      setShowCustomFilter(true);
    } else {
      setFilterValue(event.target.value);
      setShowCustomFilter(false);

    }
  };

  function calculateTimeLeft() {
    const now = new Date();
    const endOfDay = new Date();
    endOfDay.setHours(23, 59, 59, 0); // 11:59:59 PM

    let timeDiff = endOfDay.getTime() - now.getTime();

    if (timeDiff < 0) {
      timeDiff = 0; // If end of day has passed, set to zero
    }

    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return {
      hours: hours.toString().padStart(2, '0'),
      minutes: minutes.toString().padStart(2, '0'),
      seconds: seconds.toString().padStart(2, '0')
    };
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);


  // const formatDate = (dateString) => {
  //   const dateObj = new Date(parseInt(dateString,10));
  //   // console.log(dateObj);
  //   // Array of months to get the full month name
  //   const months = ['January', 'February', 'March', 'April', 'May', 'June',
  //     'July', 'August', 'September', 'October', 'November', 'December'];

  //   // Extract the components of the date
  //   const month = months[dateObj.getMonth()];
  //   const day = dateObj.getDate();
  //   const year = dateObj.getFullYear();
  //   let hours = dateObj.getHours();
  //   const minutes = ('0' + dateObj.getMinutes()).slice(-2);
  //   const period = hours >= 12 ? 'PM' : 'AM';

  //   // Convert hours from 24-hour format to 12-hour format
  //   if (hours > 12) {
  //     hours -= 12;
  //   } else if (hours === 0) {
  //     hours = 12;
  //   }
  //   return  `${month} ${day}, ${year} ${hours}:${minutes} ${period}`;
  // };

  // const calculateDifference = (profit, loss) => {
  //   const difference = (profit - loss).toFixed(2);
  //   return difference;
  // };
  
  const copyClick = (value) => {
    setRequestSubmitSuccsess(false);
    setRequestSubmitError(false);
    navigator.clipboard.writeText(value)
      .then(() => {
        setRequestSubmitSuccsess(true);
        setRequestSubmitSuccessMsg('Text copied to clipboard');
        // console.log('Text copied to clipboard');
      })
      .catch((error) => {
        setRequestSubmitError(true)
        setRequestSubmitErrorMsg('Error copying text')
        // console.error('Error copying text: ', error);
      });
  };

  const anotherFormatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
  
    return date.toLocaleString('en-US', options);
  };


  const handleCredentialsModalClose = () => {
      setIsCredentialsModal(false);
  };


  const formatStatus = (status) => {
    if (!status) return ''; // Handle case where status might be undefined or null
    const formattedStatus = status.replace(/_/g, ' '); // Replace underscores with spaces
    return formattedStatus.charAt(0).toUpperCase() + formattedStatus.slice(1); // Capitalize first letter
  };
  const handleCredentialsModalShow = async(challengeID) => {
      try {
        const response = await apiCall(API_URL +'get-challenge-details', {
            user_id: state.userData.id,
            id: challengeID,
            token: token
        });
        if (response.data.success == '0') {
          // console.log(response.data);
          setEmail(response.data.data.email);
          setAccountNumber(response.data.data.account_number);
          setMasterPassword(response.data.data.master_password);
          setIsCredentialsModal(true);
          // setChallengeDetails(response.data);
        }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
      setIsCredentialsModal(true);
  }
  // ---
  
  // ---
  const [isSnapshotModal, setIsSnapshotModal] = useState(false);
  const [snapShotTitle, setSnapShotTitle] = useState(false);

  const handleSnapshotModalClose = () => {
      setIsSnapshotModal(false);
  };
      
  const handleSnapshotModalShow = async () => {
      setIsSnapshotModal(true);
  }
  // ---


  // ---
  const [isAnalysisModal, setIsAnalysisModal] = useState(false);

  const handleAnalysisModalClose = () => {
      setIsAnalysisModal(false);
  };
      
  const handleAnalysisModalShow = async (title) => {
      // setIsAnalysisModal(true);
      try {
        const response = await apiCall(API_URL +'get-analysis-data', {
            user_id: state.userData.id,
            userWalletID: id,
            token: token
        });
        if (response.data.success == '0') {
          setSnapShotTitle(title);
          setNumberOfDays(response.data.data.numberOfDays)
          setTotalTradesTaken(response.data.data.totalTradesTaken)
          setAvgTradesPerDay(response.data.data.averageTradesPerDay)
          setTotalLotsUsed(response.data.data.totalLotsUsed)
          setAvgLotsUsed(response.data.data.averageLotsUsed)
          setBiggestWin(response.data.data.biggestWin)
          setbiggestLoss(response.data.data.biggestLoss)
          setPositiveDay(response.data.data.positiveDays)
          setNegativeDay(response.data.data.negativeDays)
          // setHighestRecordequity(response.data.)
          // setAvgPositiveDays(response.data.)
          // setAvgNegativeDays(response.data.)
          setIsAnalysisModal(true);
          // setChallengeDetails(response.data);
        }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
      setIsAnalysisModal(true);
  }

  
  // ---

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [changeMonth, setChangeMonth] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [changeSelectedDate, setChangeSelectedDate] = useState();

  const [dataForCalender, setDataForCalender] = useState(new Date());


  const [tradeTakenSelectedDay,setTradeTakenSelectedDay] = useState();
  const [endingBalanceSelectedDay,setEndingBalanceSelectedDay] = useState();
  const [usedLotsSelectedDay,setUsedLotsSelectedDay] = useState();
  const [amountSelectedDay,setAmountSelectedDay] = useState(0);
  // setamountSelectedDay
  // const [month, setMonth] = useState();
  // const [month, setMonth] = useState();

  useEffect(() => {
      const fetchData = async () => {
        const loggedInUser = getLoggedInUserData();
        const isUserLoggedIn = !!loggedInUser;
        
        if (isUserLoggedIn) {
          if (loggedInUser.id && token) {
            try {
              // getIbRequestStatus();
              const profitChart = await getProfitDataChart();
              // const tradeHistory = await getTradingHistoryList();
              // const openTrade = await getOpenTradingList();
              // const dailySummary = await getDailySummaryTradingList();
              const calenderSummary = await getDataForAccountDetailsCalender();
              const tradeDashboard = await getTradDashboardDetails();
              setDataLoaded(true);
              // console.log(tradeHistory);
              // Assuming getUserWalletGroupWise returns the data needed for wallets and walletGroups
            } catch (error) {
              navigate('/login');
              console.error('Failed to fetch ib data:', error);
            }
          }
        }
      };

      if (isInitialMount.current) {
        isInitialMount.current = false;
        fetchData();
      }
  }, [token]);

  
 
  const getTradingHistoryList = async () => {
    try {
      // setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL +'get-trading-history-list', {
          user_id: state.userData.id,
          token: token,
          page:pageTradingHistory,
          per_page: 5,
          user_wallet_id:id
      });
      if (response.data.success == '0') {
          // console.log('trading list history',response.data.data.last_page);
          setTradingHistoryList(response.data.data.data)
          setTotalPagesTradingHistory(response.data.data.last_page)
      }
      // setIsLoading(false);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };

  const getDataForAccountDetailsCalender = async () => {
    try {
      // setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL +'get-data-for-account-details-calender', {
          user_id: state.userData.id,
          token: token,
          user_wallet_id:id,
          year:format(currentMonth, 'yyyy'),
          month:format(currentMonth, 'M')
      });
      if (response.data.success == '0') {
          // console.log('datatatatatacashgvkasfgasukdjfvashukfbukdhvbdla',response.data);
          setDataForCalender(response.data)
          // setTotalPagesTradingHistory(response.data.data.last_page)
      }
      // setIsLoading(false);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };


  const getDataForAccountDetailsDateWise = async () => {
    try {
      // setIsLoading(true);
      // console.log({id});
      // console.log('before', selectedDate);
      const response = await apiCall(API_URL +'get-data-for-account-details-date-wise', {
          user_id: state.userData.id,
          token: token,
          user_wallet_id:id,
          select_date:selectedDate
      });
      if (response.data.success == '0') {
        console.log('abc',response.data.data.profit - response.data.data.loss);
            const netAmount =  response.data.data.profit - response.data.data.loss;
            setTradeTakenSelectedDay(response.data.data.count);
            setEndingBalanceSelectedDay(response.data.data.ending_balance);
            setUsedLotsSelectedDay(response.data.data.lots);
            setAmountSelectedDay(netAmount);
      }
      // setIsLoading(false);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };

  
 
  const getProfitDataChart = async () => {
    try {
      // setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL +'get-trade-data-for-chart', {
          user_id: state.userData.id,
          token: token,
          user_wallet_id: id,
          filter_apply_value: filterValue,
          start_date: startDate,
          end_date: endDate
      });
      if (response.data.success == '0') {
        console.log('trading list history', response.data.data.profitData);
          setProfitChartData(response.data.data.profitData);
          // setProfitChartData(response.data.data.data)
      }
      // setIsLoading(false);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getProfitDataChart();
  },[filterValue]);
  
  useEffect(() => {
    getProfitDataChart();
  },[endDate]);

  useEffect(() => {
    if(parseInt(pageTradingHistory) > 0){
      getTradingHistoryList();
    }
  }, [pageTradingHistory]);

  useEffect(() => {
    if(parseInt(pageOpenTrading) > 0){
      getOpenTradingList();
    }
  }, [pageOpenTrading]);

  useEffect(() => {
    if(parseInt(pageDailySummary) > 0){
      getDailySummaryTradingList();
    }
  }, [pageDailySummary]);

  const getTradDashboardDetails = async () => {
    try {
      setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL +'get-trade-dashboard-details', {
          user_id: state.userData.id,
          token: token,
          userWalletID:id
      });
      if (response.data.success == '0') {
        //console.log(response.data.data.challengeInfo);
        const dashboardDetails = response.data.data;
          setAccountNumber(dashboardDetails.challengeInfo.account_number);
          setCreateDate(dashboardDetails.challengeInfo.created_at);
          setAccountStatus(dashboardDetails.challengeInfo.account_status);
          setAccountType(dashboardDetails.challengeInfo.account_type.step);
          setAccountPhase(dashboardDetails.challengeInfo.account_type.name);
          setAccountSize(dashboardDetails.challengeInfo.account_size.limit);
          // setAccountEquity(dashboardDetails.challengeInfo.available_balance);
          // setAccountBalance(dashboardDetails.challengeInfo.available_balance);
          // setAccountEquity(dashboardDetails.challengeInfo.equity);
          setStartTradePeriod(dashboardDetails.startPeriodDate.created_at);
          setEndTradePeriod(dashboardDetails.endPeriodDate.created_at);
          setAvgWin(dashboardDetails.winAverage);
          setAvgLoss(dashboardDetails.lossAverage);
          // setDailyMaxLossLimit(dashboardDetails.dailyMaxLossLimit)
          // setMaxOverallLossLimit(dashboardDetails.maxOverallLossLimit)
          setProfileTarget(dashboardDetails.profitTarget)
          // setDailyMaxThreadShold(dashboardDetails.dailyMaxThreadShold)
          // setMaxOverallLossThreadShold(dashboardDetails.maxOverallLossThreadShold)
          // setMakeOverAllLossPercentage(dashboardDetails.makeOverAllLossPercentage)
          // setMakeDailyLossPercentage(dashboardDetails.makeDailyLossPercentage)
          setMakeOverAllProfitPercentage(dashboardDetails.makeOverAllProfitPercentage)
          setMaxTradingDays(dashboardDetails.maxTradingDays)
          setCountMaxTradingDaysLeft(dashboardDetails.countMaxTradingDaysLeft)
          setCountMaxTradingDaysLeftPercentage(dashboardDetails.countMaxTradingDaysLeftPercentage)
          setMinBalance(dashboardDetails.minBalance);
          setMaxBalance(dashboardDetails.maxBalance);
          setMinEquity(dashboardDetails.minEquity);
          setMaxEquity(dashboardDetails.maxEquity);
          setRemainDailyLoss(dashboardDetails.remainDailyLoss);
          setRemainMaxLoss(dashboardDetails.remainMaxLoss);
          setRemainMaxProfit(dashboardDetails.remainMaxProfit);
          setTotalLoss(dashboardDetails.totalLoss);
          setTotalProfit(dashboardDetails.totalProfit);
          setWinRatio(dashboardDetails.winRatio);
          setCheckRealAccountIsCreated(dashboardDetails.checkRealAccountIsCreated);
          // setTradingHistoryList(response.data.data.data.data)
          // setPageTradingHistoryList(response.data.data.last_page)
      }
      setIsLoading(false);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };

  const getOpenTradingList = async () => {
    try {
      // setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL +'get-open-positions-list', {
          user_id: state.userData.id,
          token: token,
          page:pageOpenTrading,
          per_page: 5,
          user_wallet_id:id
      });
      if (response.data.success == '0') {
        setOpenTradingList(response.data.data.data)
        setTotalPagesOpenTrading(response.data.data.last_page)
      }
      // setIsLoading(false);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };



  const getDailySummaryTradingList = async () => {
    try {
      // setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL +'get-daily-summary-trade-list', {
          user_id: state.userData.id,
          token: token,
          page:pageDailySummary,
          per_page: 5,
          user_wallet_id:id
      });
      if (response.data.success == '0') {
          console.log(response.data.data.data);
          setDailySummaryTradingList(response.data.data.data)
          setTotalPagesDailySummary(response.data.data.last_page)
      }
      // setIsLoading(false);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if(accountStatus !== 'disabled'){
        getRealTimeDataForAccountDetail();
      }
    }, 2000);

    if(accountStatus == 'disabled'){
      clearInterval(interval);
    }

    return () => clearInterval(interval); // Cleanup on unmount
  }, [token, id,accountStatus]);

  const getRealTimeDataForAccountDetail = async () => {
    try {
      // setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL +'get-real-time-data-for-account-details', {
          user_id: state.userData.id,
          token: token,
          user_wallet_id:id
      });
      if (response.data.success == '0') {
          // console.log('real data get',response.data.data)
          setAccountBalance(response.data.data.balance)
          setAccountEquity(response.data.data.equity)
          setRemainDailyLoss(response.data.data.remainDailyLoss)
          setRemainMaxLoss(response.data.data.remainOverAllLoss)
          setDailyMaxLossLimit(response.data.data.dailyLoss)
          setMaxOverallLossLimit(response.data.data.overAllLoss)
          setMakeOverAllLossPercentage(response.data.data.totalOverAllLossPercentage)
          setMakeDailyLossPercentage(response.data.data.totalDailyLossPercentage)
          setDailyMaxThreadShold(response.data.data.dailyThreadshold)
          setMaxOverallLossThreadShold(response.data.data.overAllThreadshold)
          if(response.data.data.totalOverAllLossPercentage == 100 ||response.data.data.totalDailyLossPercentage == 100){
            setAccountStatus('disabled');
          }
      }
      // setIsLoading(false);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };

  const sendRealAccountRequest = async () => {
    try {
      setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL +'send-real-account-request', {
          user_id: state.userData.id,
          token: token,
          user_wallet_id:id
      });
      if (response.data.success == '0') {
        setApplyForRealAccount(1);
          setIsLoading(false);
      } else {
        setIsLoading(false);

      }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };

  const handleSendRealAccountRequest = () => { 
    sendRealAccountRequest();
  }

  useEffect(() => {
    if (applyForRealAccount == 1) {
      getTradDashboardDetails();
    }
  }, [applyForRealAccount]);
  
  

  const renderDays = () => {
    const days = [];
    const date = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="csr-week" key={i}>
          {date[i]}
        </div>
      );
    }

    return <div className="grid grid-cols-7 rounded-xl">{days}</div>;
  };

  // calander summary

  const renderCells = () => {
    // const monthStart = startOfMonth(currentMonth);
    // const monthEnd = endOfMonth(monthStart);
    // const startDate = startOfWeek(monthStart);
    // const endDate = endOfWeek(monthEnd);
    // const rows = [];

    // let days = [];
    // let day = startDate;
    // let formattedDate = '';
    // while (day <= endDate) {
    //   for (let i = 0; i < 7; i++) {
    //     formattedDate = format(day, 'd');
    //     const cloneDay = day;

    //     days.push(
    //       <div
    //         className={`csr-date ${
    //           !isSameMonth(day, monthStart) ? 'opacity-50' : ''
    //         } ${isSameDay(day, selectedDate) ? 'status-green2' : ''}`
            

    //       }
    //         key={day}
    //         onClick={() => onDateClick(cloneDay)}
    //       >
    //         <span className="csr-formatedate">{formattedDate}</span>
    //         <span className="csr-event">$0</span>
    //       </div>
    //     );
    //     day = addDays(day, 1);
    //   }
    //   rows.push(
    //     <div className="grid grid-cols-7 rounded-xl" key={day}>
    //       {days}
    //     </div>
    //   );
    //   days = [];
    // }

    // return <div>{rows}</div>;
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = '';
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, 'd');
        const cloneDay = day;
        const dateString = format(day, 'yyyy-MM-dd');
        const dayData = dataForCalender.data[dateString] || { profit: 0, loss: 0 };
        // console.log('asbhasfhu',dataForCalender.data);
        // console.log('asbhasfhu',dataForCalender.data[dateString]);

        const netAmount = (dayData.profit - dayData.loss) != 0 ? (dayData.profit - dayData.loss).toFixed(2) : 0;
        const eventClass = netAmount == 0 ? 'status-neutral' : netAmount < 0 ? 'status-red2' : 'status-green2';
        const eventSign = netAmount == 0 ? '$' : netAmount < 0 ? '-$' : '+$';

        days.push(
          <div
            className={`csr-date ${
              !isSameMonth(day, monthStart) ? 'opacity-50' : ''
            } ${isSameDay(day, selectedDate) ? 'selected-date' : ''} ${eventClass}`}
            key={day}
            onClick={() => onDateClick(dateString)}
          >
            <span className="csr-formatedate">{formattedDate}</span>
            <span className={`csr-event`}>
              {`${eventSign+Math.abs(netAmount)}`}
            </span>
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="grid grid-cols-7 rounded-xl" key={day}>
          {days}
        </div>
      );
      days = [];
    }

    return <div>{rows}</div>;
  };

  const renderHeader = () => {
    return (
      <div className="csr-header">
        <div className="csr-header-inner">
          <span>{format(currentMonth, 'MMMM')}</span> {format(currentMonth, 'yyyy')}
        </div>
        <div className="inline-flex" role="group">
          <button
            type="button"
            onClick={prevMonth}
            className="csr-btn"
          >
            <span className="csr-btn-inner">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" data-slot="icon" className="w-5 h-5">
                <path fillRule="evenodd" d="M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z" clipRule="evenodd"></path>
              </svg>
            </span>
          </button>
          <button
            type="button"
            onClick={nextMonth}
            className="csr-btn"
          >
            <span className="csr-btn-inner">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" data-slot="icon" className="w-5 h-5">
                <path fillRule="evenodd" d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z" clipRule="evenodd"></path>
              </svg>
            </span>
          </button>
        </div>
      </div>
    );
  };

  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
    // getDataForAccountDetailsCalender();
    setChangeMonth(addMonths(currentMonth, 1));
  };

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
    // getDataForAccountDetailsCalender();
    setChangeMonth(subMonths(currentMonth, 1));
  };

  const onDateClick = (day) => {
    console.log('selected day',day);
    setSelectedDate(day);
    setChangeSelectedDate(day);
  };

  useEffect(() => {
    if(changeSelectedDate != ''){
      getDataForAccountDetailsDateWise();
    }
  }, [changeSelectedDate]);


  useEffect(() => {
    if(changeMonth != ''){
      getDataForAccountDetailsCalender();
    }
  }, [changeMonth]);
  

  return (
    <>
     {!dataLoaded && <Loaders/>}
     {dataLoaded &&
    <div className="custome-wrapper">
      <div className="container-lg cl-custome3">
        <div className="row row-gap-4">


            {/* --- Top Section Start --- */}
            <div className="col-12">
              <div className="account-info-bx">
                <div className="aib-inner-bx">
                  <div className="aib-i-img"><img src={accountStatus == 'real' ? practitioner : student} alt="" /></div>
                  <div className="aib-i-data">
                    <div className="aib-i-name">MT5 {accountNumber}</div>
                    <div className="aib-i-created">Created At: {anotherFormatDate(createDate)}</div>
                  </div>
                  <div className={(accountStatus == 'not_passed') ? 'aib-status aib-status-red' : (accountStatus == 'refunded' ? 'aib-status aib-status-yellow' : (accountStatus == 'disabled' ? 'aib-status aib-status-yellow': (accountStatus == 'passed' ? 'aib-status aib-status-green' : 'aib-status aib-status-green')))}>{formatStatus(accountStatus)}</div>
                </div>
                <div className="d-flex justify-content-end flex-wrap cbi-gap-10px">
                  <div className="common-btn-item cbi-outline cbi-small" onClick={() => handleAnalysisModalShow('Account analysis')}><span><Icons.ArrowTrendingUpSvg />Analysis</span></div>
                  <div className="common-btn-item cbi-fill cbi-small" onClick={()=> handleCredentialsModalShow(id)}><span><Icons.KeySvg />Credentials</span></div>
                </div>
              </div>
            </div>
            {/* --- Top Section End --- */}

            {/*--congralution msg Start--*/ }
            
            {
              (accountStatus == 'passed' && checkRealAccountIsCreated == 0) &&
             <div className="col-12" id="RealAccountApply">
              <div className="common-box challange-passed status-green2">
                <div className="payout-request-btn">
                    <div className="prb-inner">
                        <div class="common-box-heading mb-0 status-green2">Congratulations</div>
                        <div class="common-box-heading cbh-small mb-1 status-green2">Challange Passed</div>
                        <div class="common-box-sub-heading status-green2">Your challenge has been passed and you can now apply for real account.</div>
                    </div>
                    {/* <div class="common-btn-item cbi-fill" onClick={handleSendRealAccountRequest}><span><Icons.KeySvg />Apply Here</span></div> */}
                </div>
              </div>
            </div> 
            }
        {/*--congralution msg End--*/ }

         {/* notifaction alert box Start */}

           {/* notifaction not passed alert box Start */}    
          {
          (accountStatus == 'disabled') &&
            <div className="col-12">
            <div className="common-box status-red2">
               <div className="notifaction-alert-box">
                  <div className="nab-left">
                    <span className="nab-alert"><Icons.NotificationsSvg /><b>Violation Alert! </b></span>
                    <span>Maximum Loss Exceeded!</span>
                    <span className="mx-1">-</span>
                    <span>Maximum Loss Exceeded!</span>
                    {/* <span>Maximum Loss Exceeded! <a href="#" class="nab-click">Click here to know more</a></span> */}
                  </div> 
                  <div className="nab-right">
                    <div className="common-btn-item cbi-fill" onClick={() => handleAnalysisModalShow('Loss')}><span><Icons.ExclamationCircleSvg />View Snapshot</span></div>
                  </div> 
               </div>      
            </div>
        </div> 
          }
           

          {/* notifaction not passed alert box Start */}  

          {/* notifaction passed alert box Start */}    
          <div className="col-12 d-none">       
              <div className="common-box status-yellow2">
                 <div className="notifaction-alert-box">
                    <div className="nab-left">
                      <span className="nab-alert"><Icons.NotificationsSvg /><b>Next Phase </b></span>
                      <span>Congratulations, you will receive your next phase account shortly. This is an automatic process</span>
                    </div> 
                    {/* <div className="nab-right">
                      <Link to="/" class="common-btn-item cbi-fill"><span><Icons.KeySvg />View Snapshot</span></Link>
                    </div>  */}
                 </div>
              </div>
          </div>    
          {/* notifaction passed alert box Start */} 


          {/* notifaction alert box End */}  


            {/* --- Account Balance section Start --- */}
            <div className="col-12">
              <div className="account-detail-row">
                  <div className="adr-left">
                    <div className="common-box common-sticky overflowhidden">

                      <div className="common-box-heading cbh-small mb-0"><Icons.CircleStackSvg />Account Balance</div>
                      <div className="account-highlight"> 
                      <div className="ah-item">${accountBalance}</div>
                      <div className="ah-item"><span>Equity:</span>${accountEquity}</div>
                        <div className="ah-item ah-status-green"><span>Profit:</span>{totalProfit != 0 && accountBalance > 0 ? parseFloat((totalProfit/accountBalance)*100).toFixed(2) : 0}% <Icons.ArrowLongUpSvg /></div>
                        {/* <div className="ah-item ah-status-red"><span>Profit:</span>5.03% <Icons.ArrowLongDownSvg /></div> */}
                      </div>
                      <AccountChart profitChartData={profitChartData} filterValue={filterValue} startDate={startDate} endDate={endDate} />
                      <div className='dashbord-filter'>
                        <button class="active" value="today" onClick={handleButtonFilterChartClick}>Today</button>
                        <button value="week" onClick={handleButtonFilterChartClick}>Week</button>
                        <button value="month" onClick={handleButtonFilterChartClick}>Month</button>
                        <button value="custom" onClick={handleButtonFilterChartClick}>Custom</button>
                      </div>
                      {showCustomFilter && (
                      <ul className="filternav">
                        <li>
                          <div className="custom-date-picker">
                             <div className="left">
                                  <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                             </div>
                             <div className="right">
                                  <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                             </div>
                          </div>
                        </li>
                      </ul>
                      )}
                    </div>
                  </div>

                  <div className="adr-right">
                    <div className="common-box">
                        <div className="account-basic-info">
                            <div className="abi-item">
                                <div className="abi-label"><Icons.TradeSvg />Platform</div>
                                <div className="abi-data"><span>Metatrader 5</span></div>
                            </div>
                            <div className="abi-item">
                                <div className="abi-label"><Icons.SwatchSvg />Account Type</div>
                                <div className="abi-data"><span>{accountType}</span></div>
                            </div>
                            <div className="abi-item">
                                <div className="abi-label"><Icons.PresentationChartLineSvg />Phase</div>
                                <div className="abi-data">{accountPhase}</div>
                            </div>
                            <div className="abi-item">
                                <div className="abi-label"><Icons.EwalletsSvg />Account size:</div>
                                <div className="abi-data">${accountSize}.00</div>
                            </div>
                            <div className="abi-item">
                                <div className="abi-label"><Icons.CalendarSvg />Start trade period:</div>
                                <div className="abi-data">{anotherFormatDate(startTradePeriod) != 'Invalid Date' ? anotherFormatDate(startTradePeriod) : ''}</div>
                            </div>
                            <div className="abi-item">
                                <div className="abi-label"><Icons.CalendarSvg /> End trade period:</div>
                                <div className="abi-data">{anotherFormatDate(endTradePeriod) != 'Invalid Date' ? anotherFormatDate(endTradePeriod) :''}</div>
                            </div>
                        </div>

                        <div className="mt-4">
                            <div className="common-box-heading cbh-small"><Icons.CurrencyDollarSvg/>Trading objectives</div>
                            <div className="row row-gap-3">

                              <LimitCardItem
                                heading="Min Trading Days"
                                leftAmount={countMaxTradingDaysLeft+" Day(s)"}
                                leftText="Left"
                                percent={countMaxTradingDaysLeftPercentage != 0 ? countMaxTradingDaysLeftPercentage.toFixed(2) : 0}
                                minTradingDay={maxTradingDays}
                              />

                              <LimitCardItem
                                heading="Daily Loss Limit"
                                lcTime={timeLeft.hours+":"+timeLeft.minutes+":"+timeLeft.seconds}
                                leftAmount={"$"+(remainDailyLoss > 0 ? remainDailyLoss.toFixed(2) : 0)}
                                leftText="Left"
                                percent={makeDailyLossPercentage != 0 ? makeDailyLossPercentage.toFixed(2) : 0}
                                maxLoss={"$"+(dailyMaxLossLimit>0?dailyMaxLossLimit.toFixed(2):0)+" daily"}
                                balance={"$"+accountEquity}
                                threshold={"$"+(dailyMaxThreadShold > 0 ? dailyMaxThreadShold.toFixed(2) : 0)}
                              />

                              <LimitCardItem
                                heading="Max Loss Limit"
                                leftAmount={"$"+(remainMaxLoss>0?remainMaxLoss.toFixed(2):0)}
                                leftText="Left"
                                percent={makeOverAllLossPercentage != 0 ? makeOverAllLossPercentage.toFixed(2) :0}
                                maxLoss={"$"+(maxOverallLossLimit>0?maxOverallLossLimit.toFixed(2):0)}
                                threshold={"$"+(maxOverallLossThreadShold>0?maxOverallLossThreadShold.toFixed(2):0)}
                              />
                              {
                                (accountStatus != 'real')&&

                                  <LimitCardItem
                                    heading="Profit Target"
                                    leftAmount={"$"+(remainMaxProfit>0?remainMaxProfit.toFixed(2):0)}
                                    leftText="Left"
                                    percent={makeOverAllProfitPercentage != 0 ? makeOverAllProfitPercentage.toFixed(2) : makeOverAllProfitPercentage}
                                    profitTarget={"$"+(profitTarget>0?profitTarget.toFixed(2):0)+" Profit target"}
                                  />
                              }

                            </div>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
            {/* --- Account Balance section End --- */}


            {/* --- Balance Overview section Start --- */}
            <div className="col-12">
              <div className="account-detail-row">
                  <div className="adr-left">
                      <div className="row row-gap-4">
                        <div className="col-6">
                          <div className="common-box">
                            <div className="common-box-heading cbh-small mb-0"><Icons.ArrowTrendingUpSvg />Average Win</div>
                        <div className="average-data averag-status-green">{avgWin != 0 ? "$"+avgWin.toFixed(2) : 0}</div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="common-box">
                            <div className="common-box-heading cbh-small mb-0"><Icons.ArrowTrendingDownSvg />Average Loss</div>
                            <div className="average-data averag-status-red">{avgLoss != 0 ? "-$"+avgLoss.toFixed(2):0}</div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="common-box">
                            <div className="common-box-heading cbh-small mb-0"><Icons.ReceiptPercentSvg />Win Ratio</div>
                          <div className="average-data">{winRatio > 0 ? winRatio.toFixed(2) : 0}%</div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="common-box">
                            <div className="common-box-heading cbh-small mb-0"><Icons.CircleStackSvg />Profit Factor</div>
                          <div className="average-data">{totalProfit > 0 && totalLoss != 0 ? (totalProfit/totalLoss).toFixed(2) : 0}</div>
                          </div>
                        </div>
                      </div>
                  </div>

                  <div className="adr-right">
                    <div className="common-box">
                      <div className="row row-gap-3">

                            <ScaleOverviewItem
                              heading="Balance Overview"
                              min={"$"+minBalance != null ? minBalance : 0}
                              max={"$"+maxBalance != null ? maxBalance : 0}
                              percent={(minBalance > 0 && maxBalance > 0) ? 100 * (accountBalance-minBalance) / (maxBalance-minBalance) : 0 }
                              current={"$"+accountBalance}
                            />

                            <ScaleOverviewItem
                              heading="Equity Overview"
                              min={"$"+minEquity != null ? minEquity : 0}
                              max={"$"+maxEquity != null ? maxEquity : 0}
                              percent={(minEquity > 0 && maxEquity > 0) ? 100 * (accountEquity-minEquity) / (maxEquity-minEquity) : 0 }
                              current={"$"+accountEquity}
                            />
                        
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            {/* --- Balance Overview section End --- */}

            {/* --- Calender summary section Start --- */}
            <div class="col-12 "> 
                <div className="calender-summary-row ">
                    <div className="csr-left">
                        <div className="common-box">
                          <div className="bg-white rounded-2xl col-span-2 csr-calender ">
                              {renderHeader()}
                              {renderDays()}
                              {renderCells()}
                          </div>
                        </div>
                    </div>
                    <div className="csr-right">
                        <div className="common-box h-460">
                            <div class="common-box-heading cbh-small"><Icons.CalendarSvg /> Summary for the day</div>
                              <div className="calender-summary-section">
                                  <div className="css-inner">
                                      <div class="common-box css-box">
                                        <div className="common-box-heading cbh-small mb-0">
                                            Date
                                        </div>
                                        <div className="common-box-sub-heading">
                                            {format(selectedDate,'EEE dd MMM')}
                                        </div>
                                      </div>

                                      <div class="common-box css-box">
                                        <div className="common-box-heading cbh-small mb-0">
                                            Ending balance
                                        </div>
                                        <div className="common-box-sub-heading">
                                            ${endingBalanceSelectedDay}
                                        </div>
                                      </div>

                                      <div class="common-box css-box">
                                        <div className="common-box-heading cbh-small mb-0">
                                            Trades taken
                                        </div>
                                        <div className="common-box-sub-heading">
                                            {tradeTakenSelectedDay}
                                        </div>
                                      </div>

                                      <div class="common-box css-box">
                                        <div className="common-box-heading cbh-small mb-0">
                                            Lots used
                                        </div>
                                        <div className="common-box-sub-heading">
                                            {usedLotsSelectedDay}
                                        </div>
                                      </div>
                                  </div>

                                  <div className={amountSelectedDay === 0 ? "css-status mt-3" : amountSelectedDay < 0 ? "css-status mt-3 status-red2" : "css-status mt-3 status-green2"}>
                                      <div className="cs-left">
                                          Profit
                                      </div>
                                      <div className="cs-right">
                                          {amountSelectedDay != 0 ? (amountSelectedDay > 0) ? '+$'+Math.abs(amountSelectedDay).toFixed(2) : '-$'+Math.abs(amountSelectedDay).toFixed(2) : '$0'}
                                      </div>
                                  </div>
                              </div> 
                        </div>
                    </div>
                </div>
            </div>
            {/* --- Calender summary section End --- */}


            {/* --- Daily summary section start --- */}
            <div className="col-12">
              <div className="common-box">
                <div className="common-box-heading cbh-small">Daily Summary</div>

                <div className="table-responsive custom-table-responsive">
                    <table className="custom-table">
                        <thead>
                            <tr>
                                <th>Sr.</th>
                                <th>Date</th>
                                <th>Trades</th>
                                <th>Lots</th>
                                <th>Result</th>
                            </tr>
                    </thead>
                    { !isLoading && 
                    <tbody>
                      {(dailySummaryTradingList && dailySummaryTradingList.length > 0 && !isLoading) &&
                          dailySummaryTradingList.map((dailySummaryData, index) => (
                            <tr key={index}>
                              <td>{++index}</td>
                              <td>{anotherFormatDate(dailySummaryData.date_formatted)}</td>
                              <td>{dailySummaryData.total_trade}</td>
                              <td>{dailySummaryData.lots}</td>
                              <td>
                               {
                                  (dailySummaryData.profit - dailySummaryData.loss) > 0 ? (
                                    <span className="td-status td-green">${(dailySummaryData.profit - dailySummaryData.loss).toFixed(2)}</span>
                                  ) : (dailySummaryData.profit - dailySummaryData.loss) < 0 ? (
                                    <span className="td-status td-red">-${(dailySummaryData.loss - dailySummaryData.profit).toFixed(2)}</span>
                                  ) : (
                                    <span>$0</span>
                                  )
                                }
                              </td>
                          </tr>
                          ))
                      }
                      {(!dailySummaryTradingList && dailySummaryTradingList.length == 0 && !isLoading) &&
                         <tbody>
                         <tr>
                           <td colspan="5">
                             <EmptyBox className="empty-div-bx" eh="data is not found" esh="data is not found" buttonLink="" buttonText="" />
                             </td>
                               </tr>
                             </tbody>
                      }  
                      </tbody>
                    }
                    </table>
                   
                </div>
                <div className="empty-box">
                        {(dailySummaryTradingList.length <= 0) &&
                            <EmptyBox className="empty-div-bx" eh="Data is not found" esh="Try adjusting filters to get results." />
                        }
                    </div>

                  {(dailySummaryTradingList && dailySummaryTradingList.length > 0 && !isLoading) &&
                    <div className="pagenation-filter">
                      <div className="pf-left">
                        <div className="pf-label">Go to</div>
                        <div className="pf-input">
                          <input type="number" placeholder="Page" value={goPageDailySummary ? goPageDailySummary : ''} onChange={onChangePageDailySummary} />
                        </div>
                      </div>
                      <div className="pf-right">
                        <div class="pf-pagination">
                          <div class="pf-btn" onClick={changePrevPageDailySummary}>Prev</div>
                          <div class="pf-text">
                            <div>{pageDailySummary}</div>
                            <span>/</span>
                            <div>{totalPagesDailySummary}</div>
                          </div>
                          <div class="pf-btn pb-right" onClick={changeNextPageDailySummary}>Next</div>
                        </div>
                      </div>
                    </div>
                  }

              </div>
            </div>
            {/* --- Daily summary section start --- */}


            {/* --- Open Positions section start --- */}
            <div className="col-12">
              <div className="common-box">
                <div className="common-box-heading cbh-small">Open Positions</div>

                <div className="table-responsive custom-table-responsive">
                    <table className="custom-table">
                        <thead>
                            <tr>
                                <th>Sr.</th>
                                <th>Trade ID</th>
                                <th>Symbol</th>
                                <th>Type</th>
                                <th>Entry Date</th>
                                <th>Entry</th>
                                <th>Tp</th>
                                <th>Sl</th>
                                <th>Lots</th>
                                <th>Swap</th>
                                <th>Profit</th>
                            </tr>
                        </thead>
                        { !isLoading && <tbody>
                          {(openTradingList && openTradingList.length > 0 && !isLoading) &&
                              openTradingList.map((openTrading, index) => (
                                <tr key={index}>
                                  <td>{++index}</td>
                                  <td>{openTrading.trade_id}</td>
                                  <td>{(openTrading.symbol) ? openTrading.symbol.name : '-'}</td>
                                  <td>{openTrading.side == 'buy' ? (
                                    <span className="td-status td-green2">Buy</span>
                                  ) : (
                                    <span className="td-status td-red2">Sell</span>
                                  )}</td>
                                  <td>{anotherFormatDate(openTrading.open_date)}</td>
                                  <td>{openTrading.open_price}</td>
                                  <td>{openTrading.take_profit != '' ? openTrading.take_profit : '-'}</td>
                                  <td>{openTrading.stop_loss != '' ? openTrading.stop_loss : '-'}</td>
                                  <td>{openTrading.lots}</td>
                                  <td>{openTrading.swap}</td>
                                  <td>
                                    {<span className="td-status td-green">{openTrading.profit >0 ? "+$"+openTrading.profit : "$"+0}</span>}
                                  </td>
                              </tr>
                              ))
                          }
                         
                              
                          </tbody>
                          
                        }
                    </table>
                   
                  </div>
                  <div className="empty-box">
                        {(openTradingList.length <= 0) &&
                            <EmptyBox className="empty-div-bx" eh="Data is not found" esh="Try adjusting filters to get results." />
                        }
                    </div>
                  {(openTradingList && openTradingList.length > 0 && !isLoading) &&
                    <div className="pagenation-filter">
                      <div className="pf-left">
                        <div className="pf-label">Go to</div>
                        <div className="pf-input">
                          <input type="number" placeholder="Page" value={goPageOpenTrading} onChange={onChangePageOpenTrading} />
                        </div>
                      </div>
                      <div className="pf-right">
                        <div class="pf-pagination">
                          <div class="pf-btn" onClick={changePrevPageOpenTrading}>Prev</div>
                          <div class="pf-text">
                            <div>{pageOpenTrading}</div>
                            <span>/</span>
                            <div>{totalPagesOpenTrading}</div>
                          </div>
                          <div class="pf-btn pb-right" onClick={changeNextPageOpenTrading}>Next</div>
                        </div>
                      </div>
                    </div>
                  }

              </div>
            </div>
            {/* --- Open Positions section start --- */}


            {/* --- Trading History section start --- */}
            <div className="col-12">
              <div className="common-box">
                <div className="common-box-heading cbh-small">Trading History</div>

                <div className="table-responsive custom-table-responsive">
                  <table className="custom-table">
                    <thead>
                        <tr>
                            <th>Sr.</th>
                            <th>Trade ID</th>
                            <th>Symbol</th>
                            <th>Type</th>
                            <th>Open Date</th>
                            <th>Open</th>
                            <th>Closed Date</th>
                            <th>Close</th>
                            <th>Tp</th>
                            <th>Sl</th>
                            <th>Lots</th>
                            <th>Commission</th>
                            <th>Profit</th>
                        </tr>
                    </thead>
                    { !isLoading && <tbody>
                      {(tradingHistoryList && tradingHistoryList.length > 0 && !isLoading) &&
                          tradingHistoryList.map((tradingHistory, index) => (
                            <tr key={index}>
                              <td>{++index}</td>
                              <td>{tradingHistory.trade_id}</td>
                              <td>{(tradingHistory.symbol) ? tradingHistory.symbol.name : '-'}</td>
                              <td>{tradingHistory.side == 'buy' ? (
                                <span className="td-status td-green2">Buy</span>
                              ) : (
                                <span className="td-status td-red2">Sell</span>
                              )}</td>
                              <td>{anotherFormatDate(tradingHistory.open_date)}</td>
                              <td>{tradingHistory.open_price}</td>
                              <td>{anotherFormatDate(tradingHistory.close_date)}</td>
                              <td>{tradingHistory.close_price}</td>
                              <td>{tradingHistory.take_profit != '' ? tradingHistory.take_profit : '-'}</td>
                              <td>{tradingHistory.stop_loss != '' ? tradingHistory.stop_loss : '-'}</td>
                              <td>{tradingHistory.lots}</td>
                              <td>{tradingHistory.commission != '' ? tradingHistory.commission: '-'}</td>
                              <td>
                              {
                                  tradingHistory.profit > 0 ? (
                                    <span className="td-status td-green">${(tradingHistory.profit).toFixed(2)}</span>
                                  ) : tradingHistory.loss > 0 ? (
                                    <span className="td-status td-red">-${(tradingHistory.loss).toFixed(2)}</span>
                                  ) : (
                                    <span>$0</span>
                                  )
                                }
                              </td>
                          </tr>
                          ))
                      }
                          
                      </tbody>
                    }
                  </table>
                    
                  </div>
                  <div className="empty-box">
                        {(tradingHistoryList.length <= 0) &&
                            <EmptyBox className="empty-div-bx" eh="Data is not found" esh="Try adjusting filters to get results." />
                        }
                    </div>

                  {(tradingHistoryList && tradingHistoryList.length > 0 && !isLoading) &&
                    <div className="pagenation-filter">
                      <div className="pf-left">
                        <div className="pf-label">Go to</div>
                        <div className="pf-input">
                          <input type="number" placeholder="Page" value={goPageTradingHistory} onChange={onChangePageTradingHistory} />
                        </div>
                      </div>
                      <div className="pf-right">
                        <div class="pf-pagination">
                          <div class="pf-btn" onClick={changePrevPageTradingHistory}>Prev</div>
                          <div class="pf-text">
                            <div>{pageTradingHistory}</div>
                            <span>/</span>
                            <div>{totalPagesTradingHistory}</div>
                          </div>
                          <div class="pf-btn pb-right" onClick={changeNextPageTradingHistory}>Next</div>
                        </div>
                      </div>
                    </div>
                  }

              </div>
            </div>
            {/* --- Trading History section start --- */}

            <div className="col-12">
                <div className="common-box">
                  <div className="legal-content disclaimer">
                    <div className="legal-heading ul-red mb-2">DISCLAIMER</div>
                      <ul className="ul-red ul-style-none">
                        <li>TRADING RESULTS ON THIS DASHBOARD ARE INFORMATIVE ONLY. REAL-TIME TRADING UPDATES CAN BE MONITORED THROUGH THE TRADING PLATFORM. THE ABOVE ACCOUNT STATISTICS ARE AUTOMATICALLY UPDATED PERIODICALLY WHEN YOU HAVE ACTIVE TRADE(S). IN CASE OF ANY DISCREPANCIES, PLEASE BE PATIENT FOR THE DATA TO SYNC, IF THE ISSUE STILL PERSISTS PLEASE CONTACT US THROUGH HELP CENTER.</li>
                      </ul>
                    </div>
                  </div>  
            </div>

        </div>
      </div>
    </div>
     }
    

    {/* --- Credentials Modal --- */}
    <Modal className="zoom custom-content" centered show={isCredentialsModal} onHide={handleCredentialsModalClose}>
      <div className="custom-modal-header">
          <div className="cmh-lable">Account Credentials</div>
          <div className="cmh-sub-lable">#{accountNumber}</div>
  
          <span className="close-icon" onClick={handleCredentialsModalClose}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
              </svg>
          </span>
      </div>

      <div className="custom-modal-body">
          <div className="challenge-credentials-bx">
              <div className= {"credential-item "+ email != '' ? 'd-none':''}>
                  <div className="credential-label">Email</div>
              <div className="credential-data">{email}<span className="credential-icon" onClick={() => copyClick(email)}><Icons.CopySvg /></span></div>
              </div>
              <div className="credential-item">
                  <div className="credential-label">Username</div>
                  <div className="credential-data">{accountNumber}<span className="credential-icon" onClick={() => copyClick(accountNumber)}><Icons.CopySvg /></span></div>
              </div>
              <div className="credential-item">
                  <div className="credential-label">Password</div>
                  <div className="credential-data">{masterPassword}<span className="credential-icon" onClick={() => copyClick(masterPassword)}><Icons.CopySvg /></span></div>
              </div>
              <div className="credential-item">
                  <div className="credential-label">Server</div>
                  <div className="credential-data">Winprofx-Live<span className="credential-icon" onClick={() => copyClick("Winprofx-Live")}><Icons.CopySvg /></span></div>
              </div>
              <div className="credential-item">
                  <div className="credential-label">Platform</div>
                  <div className="credential-data">Metatrader 5 <span className="credential-icon" onClick={() => copyClick("Metatrader 5")}><Icons.CopySvg /></span></div>
              </div>
          </div>
          <div className="mt-3 mb-4">
            <div className="common-btn-item cbi-fill mx-auto"><span>Open META TRADER 5</span></div>
          </div>
      </div>
    </Modal>


    {/* --- Credentials Modal --- */}
    <Modal className="zoom custom-content" centered show={isAnalysisModal} onHide={handleAnalysisModalClose}>
      <div className="custom-modal-header">
          <div className="cmh-lable">{snapShotTitle}</div>
  
          <span className="close-icon" onClick={handleAnalysisModalClose}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
              </svg>
          </span>
      </div>

      <div className="custom-modal-body">
          <div className="common-data-bx">

              <div className="cdb-item">
                  <div className="cdb-label">Number of days</div>
                  <div className="cdb-data">{numberOfDays}</div>
              </div>

              <div className="cdb-item">
                  <div className="cdb-label">Total trades taken</div>
                  <div className="cdb-data">{totalTradesTaken > 0 ? totalTradesTaken.toFixed(2) :0}</div>
              </div>

              <div className="cdb-item">
                  <div className="cdb-label">Average trades per day</div>
                  <div className="cdb-data">{avgTradesPerDay}</div>
              </div>

              <div className="cdb-item">
                  <div className="cdb-label">Total lots used</div>
                  <div className="cdb-data">{totalLotsUsed > 0 ? totalLotsUsed.toFixed(2) : 0}</div>
              </div>

              <div className="cdb-item">
                  <div className="cdb-label">Average lots used</div>
                  <div className="cdb-data">{avgLotsUsed > 0 ? avgLotsUsed.toFixed(2) : 0}</div>
              </div>

              <div className="cdb-item">
                  <div className="cdb-label">Biggest win</div>
                  <div className="cdb-data status-green">{biggestWin > 0 ? "$"+(biggestWin.toFixed(2)) : 0}</div>
              </div>

              <div className="cdb-item">
                  <div className="cdb-label">Biggest loss</div>
                  <div className="cdb-data status-red">{biggestLoss > 0 ? "$"+(biggestLoss.toFixed(2)) : 0}</div>
              </div>

              <div className="cdb-item">
                  <div className="cdb-label">Positive days</div>
                  <div className="cdb-data">{positiveDay}</div>
              </div>

              {/* <div className="cdb-item">
                  <div className="cdb-label">Avg positive day</div>
              <div className="cdb-data status-green">${}</div>
              </div> */}

              <div className="cdb-item">
                  <div className="cdb-label">Negative days</div>
                  <div className="cdb-data">{negativeDay}</div>
              </div>

              {/* <div className="cdb-item">
                  <div className="cdb-label">Avg negative day</div>
                  <div className="cdb-data status-red">0</div>
              </div> */}

              {/* <div className="cdb-item">
                  <div className="cdb-label">Highest recorded equity</div>
                  <div className="cdb-data">$26,257.25</div>
              </div> */}

          </div>
      </div>
    </Modal>

    {/* --- Credentials Modal --- */}
    <Modal className="zoom custom-content" centered show={isSnapshotModal} onHide={handleSnapshotModalClose}>
      <div className="custom-modal-header">
          <div className="cmh-lable">Loss</div>
  
          <span className="close-icon" onClick={handleSnapshotModalClose}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
              </svg>
          </span>
      </div>

      <div className="custom-modal-body">
          <div className="common-data-bx">

              <div className="cdb-item">
                  <div className="cdb-label">Number of days</div>
                  <div className="cdb-data">12</div>
              </div>

              <div className="cdb-item">
                  <div className="cdb-label">Total trades taken</div>
                  <div className="cdb-data">15</div>
              </div>

              <div className="cdb-item">
                  <div className="cdb-label">Average trades per day</div>
                  <div className="cdb-data">8.5</div>
              </div>

              <div className="cdb-item">
                  <div className="cdb-label">Total lots used</div>
                  <div className="cdb-data">0.006</div>
              </div>

              <div className="cdb-item">
                  <div className="cdb-label">Average lots used</div>
                  <div className="cdb-data">0.08</div>
              </div>

              <div className="cdb-item">
                  <div className="cdb-label">Biggest win</div>
                  <div className="cdb-data status-green">$3.32</div>
              </div>

              <div className="cdb-item">
                  <div className="cdb-label">Biggest loss</div>
                  <div className="cdb-data status-red">-$0.7</div>
              </div>

              <div className="cdb-item">
                  <div className="cdb-label">Positive days</div>
                  <div className="cdb-data">2</div>
              </div>

              {/* <div className="cdb-item">
                  <div className="cdb-label">Avg positive day</div>
              <div className="cdb-data status-green">${}</div>
              </div> */}

              <div className="cdb-item">
                  <div className="cdb-label">Negative days</div>
                  <div className="cdb-data">5</div>
              </div>

              {/* <div className="cdb-item">
                  <div className="cdb-label">Avg negative day</div>
                  <div className="cdb-data status-red">0</div>
              </div> */}

              {/* <div className="cdb-item">
                  <div className="cdb-label">Highest recorded equity</div>
                  <div className="cdb-data">$26,257.25</div>
              </div> */}

          </div>
      </div>
    </Modal>
      <FlashMessage type="success" isVisible={requestSubmitSuccess} message={requestSubmitSuccessMsg} />
      <FlashMessage type="error" isVisible={requestSubmitError} message={requestSubmitErrorMsg} />
    </>
  )
}
