import React, { useState, useRef, useEffect } from 'react';
import Icons from '../../components/icons';
import Input from '../../components/commonfield/Input';
import SelectInput from '../../components/commonfield/SelectInput';
import PasswordInput from '../../components/commonfield/PasswordInput';
import { useAuth } from '../../context/AuthContext';
import useApi from '../../utility/apiCall';
import FlashMessage from '../../components/FlashMessage'; 
import API_URL from '../../config/config';
import * as Img from '../../components/Img';
import Loaders from '../../components/Loader';
import PhoneInput from 'react-phone-number-input'

export default function PageSettings() {
  const { apiCall } = useApi();
  const { state } = useAuth();
  const isInitialMount = useRef(true);
  const token = state.token || '';
  const [tab, setTab] = useState('profileTab');
  const [countryId, setCountryId] = useState(null);
  const [countries, setCountries] = useState(null);
  const [userDetails, setUserDetails] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    street: '',
    postalCode: '',
    city: '',
    country: '',
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitSuccessMsg, setRequestSubmitSuccessMsg] = useState(false);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [street, setStreet] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // --- Sign Up Tabbing Js Start
  // const [tab, seTab] = useState('profileTab');

  const getCountries = async () => {
      // Logic to get user wallet group-wise data
      try {
        const response = await apiCall(API_URL +'get-countries', {
            user_id: state.userData.id,
            token: token,
            nopaginate:1
        });
        if (response.data.success == '0') {
          // console.log(response.data.data);
          const countryOptions = response.data.data.map(item => ({
            value: item.id,
            name: item.name
          }));
          setCountries(countryOptions);
        }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };
  const [selectedValue, setSelectedValue] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const countries = await getCountries();
      try {
        const response = await apiCall(API_URL + 'get-profile', {
          user_id: state.userData.id,
          token: token,
          nopaginate: 1,
        });
        if (response.data.success == '0') {
          console.log(response.data);
          setEmail(response.data.data.email);
          setFirstName(response.data.data.first_name);
          setLastName(response.data.data.last_name);
          setPhoneNumber(response.data.data.phone_number);
          setStreet(response.data.data.address);
          setCity(response.data.data.city);
          setPostalCode(response.data.data.postal_code);
          setCountry(response.data.data.country);
          setSelectedValue(response.data.data.country);
          // setUserDetails({
          //   email: response.data.data.email || '',
          //   firstName: response.data.data.first_name || '',
          //   lastName: response.data.data.last_name || '',
          //   phoneNumber: response.data.data.phone_number || '',
          //   street: response.data.data.address || '',
          //   postalCode: response.data.data.postal_code || '',
          //   city: response.data.data.city || '',
          //   country: response.data.data.country || '',
          // });
        } else {
          // console.log(response.data);
          console.error('Failed to fetch user details:', response.data.error);
        }
        setDataLoaded(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (isInitialMount.current) {
        isInitialMount.current = false;
        fetchData();
    }
  }, [token]);

  

  const handleEmailChange = (value) => {
    // setEmail(value);
    setErrors((prev) => ({ ...prev, email: '' })); 
  };
  const handleCountryChange = (event) => {
    // console.log(event.target.value);
    setCountryId(event.target.value);
    setCountry(event.target.value);
    setSelectedValue(event.target.value);
    setErrors((prev) => ({ ...prev, country: '' })); 
  }
  const handleFirstNameChange = (value) => {
    setFirstName(value);
    setErrors((prev) => ({ ...prev, firstName: '' })); 
  };
  const handleLastNameChange = (value) => {
    setLastName(value);
    setErrors((prev) => ({ ...prev, lastName: '' })); 
  };
  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    setErrors((prev) => ({ ...prev, phoneNumber: '' })); 
    
  };
  const handleStreetChange = (value) => {
    setStreet(value);
    setErrors((prev) => ({ ...prev, street: '' })); 
  };
  const handleCityChange = (value) => {
    setCity(value);
    setErrors((prev) => ({ ...prev, city: '' })); 
  };
  const handlePostalCodeChange = (value) => {
    setPostalCode(value);
    setErrors((prev) => ({ ...prev, postalCode: '' })); 
  };

  const handleOldPasswordChange = (value) => {
    setOldPassword(value);
    setErrors((prev) => ({ ...prev, oldPassword: '' })); 
  };

  const handleNewPasswordChange = (value) => {
    setPassword(value);
    setErrors((prev) => ({ ...prev, newPassword: '' })); 
  };

  const handleConfirmPasswordChange = (value) => {
    setConfirmPassword(value);
    setErrors((prev) => ({ ...prev, confirmPassword: '' })); 
  };

  const handleTabClick = (tabId) => {
    setTab(tabId);
  };
  
  // Demo On Change function
  const onChange = () => { };
  
  const validateProfileFields = () => {
    const newErrors = {};
    if (!email) {
      newErrors.email = 'Email is required';
    } else if(email){
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        newErrors.email = 'Email is not valid';
      } 
    } 
    
    if (!firstName) {
      newErrors.firstName = 'First Name is required';
    }

    if (!lastName) {
      newErrors.lastName = 'Last Name is required';
    }
    if (!phoneNumber) {
      newErrors.phoneNumber = 'Phone number is required';
    } else if (!/^\+(?:[0-9] ?){6,14}[0-9]$/.test(phoneNumber)) {
        newErrors.phoneNumber = 'Enter a valid phone number';
    }

    if (!street) {
      newErrors.street = 'Street is required';
    }
    
    if (!postalCode) {
      newErrors.postalCode = 'Postal Code is required';
    }
        
    if (!city) {
      newErrors.city = 'City is required';
    }
            
    if (!country) {
      newErrors.country = 'Country is required';
    }
    return newErrors;
  };

  const validatePasswodFields = () => {
    const newErrors = {};
    if (!oldPassword) {
      newErrors.oldPassword = 'Old Password is required';
    }

    if (!newPassword) {
      newErrors.newPassword = 'Password is required';
    } else if (newPassword.length < 6) {
      newErrors.newPassword = 'New Password must be at least 6 characters long';
    }

    if (!confirmPassword) {
      newErrors.confirmPassword = 'Confirm password is required';
    } else if (confirmPassword.length < 6) {
      newErrors.confirmPassword = 'Confirm password must be at least 6 long';
    } else if (confirmPassword !== newPassword) {
        newErrors.confirmPassword = 'Password and confirm password must match';
    }
    // console.log('Errors:', newErrors);

    return newErrors;
  };


  const handleProfileSubmit = async() => {
    const valErrors = validateProfileFields();
    if (Object.keys(valErrors).length === 0) {
      // Create a FormData object
      const formData = new FormData();
      console.log('country_id',countryId);
      console.log(phoneNumber);
      formData.append('user_id',state.userData?.id);
      formData.append('token',state?.token);
      formData.append('email',email);
      formData.append('first_name',firstName);
      formData.append('last_name',lastName);
      formData.append('phone',phoneNumber);
      formData.append('country_id',countryId);
      formData.append('address',street);
      formData.append('postal_code',postalCode);
      formData.append('city',city);
      formData.append('country',country);
      
      try {
        // Send FormData using Axios
        setIsLoading(true);
        setRequestSubmitSuccsess(false)
        setRequestSubmitError(false)
        apiCall(API_URL + 'update-profile', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          if (response.data.success == 0) {
            console.log(response.data);
            setIsLoading(false);
            setRequestSubmitSuccsess(true);
            setRequestSubmitSuccessMsg('Profile updated successfully');
          } else {
            setIsLoading(false);
            setRequestSubmitError(true)
            // console.log(response.data.data.error);
            if(response.data.data.error != undefined){
              setRequestSubmitErrorMsg(response.data.data.error)
            } else {
              // console.log(response.data);
              setRequestSubmitErrorMsg(response.data.data)
            }
            // console.log('ahiya');
          }  
        })
        .catch(error => {
          setIsLoading(false);
          setRequestSubmitError(true)
          setRequestSubmitErrorMsg(error.response.data.message)
          // console.error('Error uploading profile picture:', error);
        });
      } catch (error) {
        // console.error('Error uploading files:', error);
      }
    } else {
      // Set errors to state
      setErrors(valErrors);
    }
  };

  const handlePasswordChange = async () => {
    const valErrors = validatePasswodFields();
    if (Object.keys(valErrors).length === 0) {
      // Create a FormData object
      const formData = new FormData();
      formData.append('user_id',state.userData?.id);
      formData.append('token',state?.token);
      formData.append('old_password',oldPassword);
      formData.append('password',newPassword);
      formData.append('password_confirmation',confirmPassword);
      
      try {
        // Send FormData using Axios
        setIsLoading(true);
        setRequestSubmitSuccsess(false)
        setRequestSubmitError(false)
        apiCall(API_URL + 'change-password', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          if (response.data.success == 0) {
            console.log(response.data);
            setIsLoading(false);
            setOldPassword('');
            setPassword('');
            setConfirmPassword('');
            setRequestSubmitSuccsess(true);
            setRequestSubmitSuccessMsg('Password updated successfully');
          } else {
            setIsLoading(false);
            setRequestSubmitError(true)
            setRequestSubmitErrorMsg(response.data.data)
          }
        })
        .catch(error => {
          setIsLoading(false);
          setRequestSubmitError(true)
          setRequestSubmitErrorMsg(error.response.data.message)
          //console.error('Error uploading profile picture:', error);
        });
      } catch (error) {
        //console.error('Error uploading files:', error);
      }
    } else {
      // Set errors to state
      setErrors(valErrors);
    }
  };
  // const [value, setValue] = useState()



  return (
    <>
     {!dataLoaded && <Loaders/>}
     {dataLoaded &&
    <div className="custome-wrapper">
      <div className="container-lg cl-custome3">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-10">
            <div className="common-box">
              <div className="common-box-heading">
                <Icons.Cog6Svg />
                Settings
              </div>

              <div className="common-tabs-bx mb-4">
                <div className={`ctb-item ${tab === 'profileTab' ? 'active' : ''}`} onClick={() => handleTabClick('profileTab')}>Profile</div>
                {/* <div className="ctb-item">Verify</div> */}
                <div className={`ctb-item ${tab === 'securityTab' ? 'active' : ''}`} onClick={() => handleTabClick('securityTab')}>Security</div>
              </div>
              <form className="profile-form">
                <div className={`row ${tab === 'profileTab' ? '' : 'd-none'} `} id="profileTab" style={{maxWidth:"576px", margin: "auto"}}>
                  <div className="col-12">
                    <div className="common-section-heading">Update Profile</div>
                  </div>
                  <div className="col-12">
                    <Input heading="Your email" value={email} type="email" placeholder="Enter email address" onChange={handleEmailChange} readOnly={true} errorMeg={errors?.email} error={errors?.email} />
                  </div>

                  <div className="col-6">
                    <Input heading="First Name" value={firstName} type="text" placeholder="Enter first name" onChange={handleFirstNameChange} errorMeg={errors?.firstName} error={errors?.firstName} />
                  </div>

                  <div className="col-6">
                    <Input heading="Last name" value={lastName} type="text" placeholder="Enter last name" onChange={handleLastNameChange} errorMeg={errors?.lastName} error={errors?.lastName} />
                  </div>

                  <div className="col-12 col-sm-6 ">
                    {/* <Input heading="Phone number" value={phoneNumber} type="number" placeholder="Enter phone number" onChange={handlePhoneNumberChange} errorMeg={errors?.phoneNumber} error={errors?.phoneNumber} /> */}
                    <div class="sgr-outer-label">Phone number</div>
                    <div className='signinup-group sg-country-code'>
                      
                      <PhoneInput
                        heading="Phone number"
                        international
                        placeholder="Enter phone number"
                        // errorMeg={errors?.phoneNumber} error={errors?.phoneNumber}
                        value={phoneNumber}
                        defaultCountry="IN"
                        onChange={handlePhoneNumberChange}/>
                        {errors?.phoneNumber && <div className='form-error-msg'>{errors?.phoneNumber}</div>}

                      </div>

                  </div>

                  {/* <div className="col-sm-6">
                    <Input heading="Date Of Birth" type="date" placeholder="Enter Date Of Birth" errorMeg="Date Of Birth is required" />
                  </div> */}

                  <div className="col-12">
                    <div className='common-section-separator'></div>
                  </div>


                  <div className="col-12">
                    <div className="common-section-heading">Update Address</div>
                  </div>
                  
                  <div className="col-sm-12">
                    <Input heading="Street" type="text" placeholder="Enter Street" value={street} onChange={handleStreetChange} errorMeg={errors?.street} error={errors?.street} />
                  </div>
                  
                  <div className="col-sm-6">
                    <Input heading="Postal code" value={postalCode} type="number" placeholder="Enter Postal code" onChange={handlePostalCodeChange} errorMeg={errors?.postalCode} error={errors?.postalCode} />
                  </div>

                  <div className="col-12"></div>

                  <div className="col-sm-6">
                    <Input heading="City" type="text" placeholder="Enter City" value={city} onChange={handleCityChange} errorMeg={errors?.city} error={errors?.city} />
                  </div>

                  <div className="col-sm-6">
                    <SelectInput heading="Country"  options={countries} selectedValue={country} onChange={handleCountryChange} defaultOption={'Select Country'} value={country} errorMeg={errors?.country} error={errors?.country} />
                  </div>

                  <div className="col-12 mt-2">
                      {/* <div className="common-submit-btn" onClick={handleProfileSubmit}>Update</div> */}
                      {!isLoading && <div className="common-submit-btn" onClick={handleProfileSubmit}>Update</div>
                    }
                    {isLoading && <div className="lsb-loader-btn">
                      <img src={Img.loading} alt="" />Please wait ....
                    </div>
                    }
                    {/* <div className="lsb-loader-btn"><img src={Img.loading} alt="" />Please wait ....</div> */}
                  </div>

                </div>
              </form>

              <form className="password-form">
                  
                <div className={`row ${tab === 'securityTab' ? '' : 'd-none'} `} id="securityTab" style={{maxWidth:"576px", margin: "auto"}}>
                  <div className="col-12">
                    <div className="common-section-heading">Change Password</div>
                  </div>
                  
                  <div className="col-12">
                    <PasswordInput heading="Old Password" type="password" value={oldPassword} placeholder="Enter old password" onChange={handleOldPasswordChange} errorMeg={errors?.oldPassword || ''} error={errors?.oldPassword || ''} />
                  </div>

                  <div className="col-12">
                      <PasswordInput heading="New Password" type="password" value={newPassword} placeholder="Enter New password" onChange={handleNewPasswordChange} errorMeg={errors?.newPassword || ''} error={errors?.newPassword || ''} />
                  </div>

                  <div className="col-12">
                      <PasswordInput heading="Confirm password" type="password" value={confirmPassword} placeholder="Enter confirm password" onChange={handleConfirmPasswordChange}  errorMeg={errors?.confirmPassword || ''} error={errors?.confirmPassword || ''} />
                  </div>

                  <div className="col-12 mt-2">
                      {/* <div className="common-submit-btn" onClick={handlePasswordChange}>Change Password</div> */}
                      {!isLoading && <div className="common-submit-btn" onClick={handlePasswordChange}>Change Password</div>
                      }
                      {isLoading && <div className="lsb-loader-btn">
                        <img src={Img.loading} alt="" />Please wait ....
                      </div>
                      }
                    {/* <div className="lsb-loader-btn"><img src={Img.loading} alt="" />Please wait ....</div> */}
                  </div>

                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
      </div>
      }
      <FlashMessage type="success" isVisible={requestSubmitSuccess} message={requestSubmitSuccessMsg} />
        <FlashMessage type="error" isVisible={requestSubmitError} message={requestSubmitErrorMsg} />
    </>
  )
}
