import React from 'react'

export default function FaqsWidgets() {
  return (
    <div className="common-box">
        <div className="common-box-heading"><span className="cbh-color">F</span>requently <br/> <span className="cbh-color">A</span>sked <br/> <span className="cbh-color">Q</span>uestions</div>
        <div className="faqs-info-bx">
            <div className="faqs-info-item">General</div>
            <div className="faqs-info-item">Evaluation Process</div>
            <div className="faqs-info-item">Trading Objectives</div>
            <div className="faqs-info-item">Trading Rules</div>
            <div className="faqs-info-item">Funded Firm Accounts</div>
        </div>
    </div>
  )
}
