import React from 'react';
import { Link } from 'react-router-dom'

// Icons
import Icons from '../../components/icons';

const ChallengeCard = ({to, className, imgSrc, name, type, step, status, trades, days, statusClass, CredentialsClick,BoxClick}) => {
  return (
    <div className="col-12">
      <div className={`challenge-card-bx ${className}`}>
        <div className="ccb-inner" onClick={BoxClick}>
          <div className="challenge-top-bx">
            <div className="ccb-info-bx">
              <div className="ccb-i-img"><img src={imgSrc} alt='' /></div>
              <div className="ccb-i-data">
                <div className="ccb-i-name">{name}</div>
                <div className="ccb-i-type">{type} <span>{step}</span></div>
              </div>
            </div>
            <div className={`ccb-status ${statusClass}`}>{status}</div>
          </div>

          <div className="challenge-trade-info">
            <div className="cti-item">
              <div className="cti-icon">
                <Icons.TradeSvg />
              </div>
              <div className="cti-data-bx">
                <div className="cti-label">No. of trades</div>
                <div className="cti-num">{trades}</div>
              </div>
            </div>

            <div className="cti-item">
              <div className="cti-icon">
                <Icons.CalendarSvg />
              </div>
              <div className="cti-data-bx">
                <div className="cti-label">Days traded</div>
                <div className="cti-num">{days}</div>
              </div>
            </div>
          </div>

          <div className="challenge-btns">
            <div className="common-btn-item cbi-outline cbi-small" onClick={CredentialsClick}><span><Icons.KeySvg />Credentials</span></div>
            <Link to={to} className="common-btn-item cbi-fill cbi-small"><span><Icons.ChartPieSvg />Dashboard</span></Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChallengeCard;
