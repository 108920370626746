import { useAuth } from '../context/AuthContext';
import axios from 'axios';

const useApi = () => {
  const { state, handleUnauthorized } = useAuth();

  const apiCall = async (url, data, config = {}) => {
    try {
      // Add Authorization header if token is present
      if (state.token) {
        if (!config.headers) {
          config.headers = {};
        }
        config.headers['Authorization'] = `Bearer ${state.token}`;
      }
      const response = await axios.post(url, data, config);
      return response;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          handleUnauthorized();
        } else {
          console.error('An error occurred:', error.response.status, error.response.data);
        }
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up request:', error.message);
      }
      throw error; // Re-throw the error to allow further handling if needed
    }
  };

  return { apiCall };
};

export default useApi;
