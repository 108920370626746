import React from 'react'

// Icons & Imges common files
import * as Img from '../../components/Img';
import Icons from '../../components/icons'

export default function BestTypeItem({ heading, name, amount, data }) {
  return (
    <>
    <div className="best-type-bx">
      <div className="b-type-heading"><Icons.SparklesSvg />{heading}</div>
      <img className="b-type-img" src={Img.rank1} alt="" />
      <div className="b-type-name">{name}</div>
      <div className="b-type-amount">{amount}</div>
      <div className="b-type-data">{data}</div>
    </div>
    </>
  )
}
