import React from 'react';
import { Link } from 'react-router-dom';
// Icons & Image common file
import Icons from '../../components/icons';

export default function PayoutRequestBtn() {
  return (
    <div className="col-12">
        <div class="common-box-heading mb-2">Welcome To The Payout Section.</div>
        <div className="common-box">
            <div className="payout-request-btn">
                <div className="prb-inner">
                    <div class="common-box-heading cbh-small mb-1">Ready to request your payout?</div>
                    <div class="common-box-sub-heading">Please click on the request payout button then proceed to fill out the required information, our team will reach out to you for further advancements.</div>
                </div>
                <Link to="/payouts-request" class="common-btn-item cbi-fill"><span><Icons.KeySvg /> Request Payout</span></Link>
            </div>
        </div>
    </div>
  )
}
  