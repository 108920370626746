import React, { useState, useRef, useEffect } from 'react'

// Icons & images common files
import * as Img from '../../components/Img';
import Icons from '../../components/icons'
import EmptyBox from '../../components/EmptyBox'; 
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { useNavigate,useParams } from 'react-router-dom';
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';
import FlashMessage from '../../components/FlashMessage';

export default function BestAccounts() {
  let accountsData = [];
  //Auth related var

  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const token = state.token || '';
  const isInitialMount = useRef(true);

  const { id } = useParams();
  //Loader
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitSuccessMsg, setRequestSubmitSuccessMsg] = useState(false);
  const [addDataIntoArray, setDataIntoArray] = useState();
  const [addsecondDataIntoArray, setSecondDataIntoArray] = useState([]);
  const [isFIlter, setIsFIlter] = useState(0);

  // const accountData = addDataIntoArray.length > 0 ? addDataIntoArray : bestAccountData;
  // if (bestAccountData.length > 0) {
  //   accountsData = [
  //     bestAccountData.map(item => ({
  //         img: Img.rank1, 
  //         name: item.first_name+' '+item.last_name, 
  //         country: '', 
  //         accountSize: "$"+item.account_size, 
  //         profit: "$"+item.profit, 
  //         profitPercentage: '49.22%'
  //         // img: Number(item.profit),
  //         // month: anotherFormatDate(item.date_formatted)
  //     }))
  //     // { img: Img.rank1, name: 'Wilcles P', country: 'US',  accountSize: '$5,000', profit: '$2,4610', profitPercentage: '49.22%' },
  //     // { img: Img.rank2, name: 'Kseniia B', country: 'UA', accountSize: '$10,000', profit: '$40,132.76', profitPercentage: '40.13%' },
  //     // { img: Img.rank3, name: 'Landon H', country: 'US',  accountSize: '$25,000', profit: '$37,392.54', profitPercentage: '37.39%' },
  //     // { img: Img.rank3, name: 'Vladyslav P', country: 'US', accountSize: '$100,000', profit: '$5,547', profitPercentage: '37.39%' },
  //     // { img: Img.rank3, name: 'Giovanni R', country: 'UA', accountSize: '$100,000', profit: '$2,654', profitPercentage: '27.39%' },
  //     // { img: Img.rank3, name: 'Radmir Z', country: 'RU',  accountSize: '$50,000', profit: '$6,752', profitPercentage: '23.39%' },
  //     // { img: Img.rank3, name: 'Ran T', country: 'CN',  accountSize: '$100,000', profit: '$12,548', profitPercentage: '20.39%' },
  //   ];
    
  // }
  const [activeTab, setActiveTab] = useState(0); // State to manage active tab
  const handleTabClick = (tabName) => {
    setIsFIlter(1);
    setActiveTab(tabName); // Set the active tab to the clicked tab
  };

  useEffect(() => {
    const fetchData = async () => {
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;
      
      if (isUserLoggedIn) {
        if (loggedInUser.id && token) {
          try {
            const accountProfit = await getBestActiveProfitData();
            setDataLoaded(true);
            // console.log(tradeHistory);
            // Assuming getUserWalletGroupWise returns the data needed for wallets and walletGroups
          } catch (error) {
            console.error('Failed to fetch ib data:', error);
          }
        }
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchData();
    }
  }, [token]);

  const filterByAccountSize = (array, size) => {
    return array.filter(item => item.account_size === size);
  };

  const getBestActiveProfitData = async () => { 
    try {
      setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL +'get-best-account-profit', {
          user_id: state.userData.id,
          token: token,
          account_size: activeTab,
          is_filter:isFIlter
      });
      if (response.data.success == '0') {
        // console.log(response.data.data);
        setDataIntoArray(response.data.data);
        if(activeTab == 0){
          setSecondDataIntoArray(response.data.data);
        }
      }
      setIsLoading(false);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    if (isFIlter == 1) {
      getBestActiveProfitData();
    }
  },[activeTab]);

  return (
    <div className="col-12">
      <div className="common-box">
        <div className="common-box-heading cbh-small"><Icons.SparklesSvg /> Best Accounts In Profit</div>

        <div className="common-tabs-bx ctb-style-2 mt-4 mb-1">
          <div className={`ctb-item ${activeTab === 0 ? 'active' : ''}`} onClick={() => handleTabClick(0)}>
            All
          </div>
          <div className={`ctb-item ${activeTab === 5000 ? 'active' : ''}`} onClick={() => handleTabClick(5000)}>
            $5,000
          </div>
          <div className={`ctb-item ${activeTab === 15000 ? 'active' : ''}`} onClick={() => handleTabClick(15000)}>
            $15,000
          </div>
          <div className={`ctb-item ${activeTab === 25000 ? 'active' : ''}`} onClick={() => handleTabClick(25000)}>
            $25,000
          </div>
          <div className={`ctb-item ${activeTab === 50000 ? 'active' : ''}`} onClick={() => handleTabClick(50000)}>
            $50,000
          </div>
          <div className={`ctb-item ${activeTab === 100000 ? 'active' : ''}`} onClick={() => handleTabClick(100000)}>
            $100,000
          </div>
          <div className={`ctb-item ${activeTab === 200000 ? 'active' : ''}`} onClick={() => handleTabClick(200000)}>
            $200,000
          </div>
        </div>

        <div className="table-responsive custom-table-responsive ctr-style-2">
          <table className="custom-table ct-style-2">
            <thead>
              <tr>
                <th>User</th>
                <th>Account Size</th>
                <th>Profit</th>
                <th>Profit%</th>
              </tr>
            </thead>
            <tbody>
              {
                (addDataIntoArray && addDataIntoArray.length > 0) &&
                  addDataIntoArray.map((account, index) => (
                    <tr key={index}>
                  <td>
                    <div className="td-user-rank">
                      <div className="td-u-sr">{index + 1}</div>
                      <img className="td-u-img" src={addsecondDataIntoArray[0].id == account.id ? Img.rank1 : addsecondDataIntoArray[1].id == account.id ? Img.rank2 : Img.rank3} alt="" />
                      <div className="td-u-name">{account.first_name+" "+account.last_name}<span>{account.country}</span></div>
                    </div>
                  </td>
                  <td>{"$"+account.account_size}</td>
                  <td><span className="td-status td-green"><Icons.ArrowTrendingUpSvg />{"$"+account.profit}</span></td>
                  <td><span className="td-status td-green"><Icons.ArrowTrendingUpSvg />{((account.profit/account.account_size)*100).toFixed(2)+"%"}</span></td>
                </tr>
                  ))
              }
            </tbody>
          </table>
              <div className="empty-box">
                  {(addDataIntoArray && addDataIntoArray.length <= 0) &&
                      <EmptyBox className="empty-div-bx" eh="Data is not found" esh="Try adjusting filters to get results." />
                  }
              </div>
        </div>
      </div>
    </div>
  )
}
