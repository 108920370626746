import React, { useState, useRef, useEffect } from "react";

import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { useNavigate,useParams } from 'react-router-dom';
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';
import FlashMessage from '../../components/FlashMessage';
import Button from 'react-bootstrap/Button';
import EmptyBox from '../../components/EmptyBox';


import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

// Icons & imgeas common files
import Icons from '../../components/icons'
import Loaders from '../../components/Loader';

// 
import Breadcrumb from '../../components/Breadcrumb'

import SelectInput from '../../components/commonfield/SelectInput'



export default function PageCalendar() {

    
  //Auth related var

  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const token = state.token || '';
  const isInitialMount = useRef(true);

  //Loader
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitSuccessMsg, setRequestSubmitSuccessMsg] = useState(false);

    const [currencyData, setCurrencyData] = useState([]);
    const [isFilter, setIsFilter] = useState(0);
    const [selectCurrency, setSelectCurrency] = useState('');
    const [newsData, setNewsData] = useState('');
    const [collapseAdd, setCollapseAdd] = useState(-1); // Initialize with -1 to indicate no item is expanded
    const [collapseStatus, setCollapseStatus] = useState('');
    const [selectedDays, setSelectedDays] = useState([]);
    const [datesArray, setDatesArray] = useState([]);

    const getDateForDayOfWeek = (day) => {
        const today = new Date();
        const daysOfWeek = {
          mon: 1,
          tue: 2,
          wed: 3,
          thu: 4,
          fri: 5,
        };
        
        // Calculate the difference in days from today to the given day
        const currentDay = today.getDay() || 7; // JavaScript's getDay() is 0-indexed (Sun=0)
        const targetDay = daysOfWeek[day];
        const dayDifference = targetDay - currentDay;
        
        // Adjust if targetDay is in the past of the current week
        const targetDate = new Date();
        targetDate.setDate(today.getDate() + dayDifference);
        
        return targetDate.toISOString().split('T')[0]; // Return date in YYYY-MM-DD format
      };
    

    const handleCollaps = (index) => {
        if (collapseStatus == 'active' && index == collapseAdd) {
            // If the clicked item is already expanded, collapse it
            setCollapseAdd(-1);
            setCollapseStatus('');
        } else {
            // Expand the clicked item and collapse the previously expanded item (if any)
            setCollapseAdd(index);
            setCollapseStatus('active');
        }
    };

      const [activeButtons, setActiveButtons] = useState(['high', 'medium', 'low', 'no_impact']);

        const handleButtonClick = (id, value) => {
            setActiveButtons(prev => {
            const newActiveButtons = [...prev];
            if (newActiveButtons.includes(value)) {
                // Remove from the array if already present
                return newActiveButtons.filter(item => item !== value);
            } else {
                // Add to the array if not present
                return [...newActiveButtons, value];
            }
            });
        };
    


    useEffect(() => {
        const fetchData = async () => {
          const loggedInUser = getLoggedInUserData();
          const isUserLoggedIn = !!loggedInUser;
          
          if (isUserLoggedIn) {
            if (loggedInUser.id && token) {
              try {
                  // getIbRequestStatus();
                  const currencyGetData = await getCurrencyDataList();
                  const newsGetData = await getNewsDataList();
                setDataLoaded(true);
                // console.log(tradeHistory);
                // Assuming getUserWalletGroupWise returns the data needed for wallets and walletGroups
              } catch (error) {
                navigate('/login');
                console.error('Failed to fetch ib data:', error);
              }
            }
          }
        };
  
        if (isInitialMount.current) {
          isInitialMount.current = false;
          fetchData();
        }
    }, [token]);

    // let currencyData = currencyData;

    const getCurrencyDataList = async () => {
        try {
          setIsLoading(true);
          // console.log({id});
          const response = await apiCall(API_URL +'get-currency-records', {
              user_id: state.userData.id,
              token: token,
          });
          if (response.data.success == '0') {
              console.log('trading list history',response.data.data);
              setCurrencyData(response.data.data);
            //   setTotalPagesTradingHistory(response.data.data.last_page)
          }
          setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        getNewsDataList();
    },[datesArray]);

    useEffect(() => {
        if (activeButtons) {
            getNewsDataList();
        }
    },[activeButtons]);

    useEffect(() => {
        if (selectCurrency != '') {
            getNewsDataList();
        }
    }, [selectCurrency]);
    
    const getRowClass = (eventType) => {
        switch (eventType) {
            case 'low':
                return 'light-green';
            case 'medium':
                return 'light-yellow';
            case 'high':
                return 'light-red';
            case 'no_impact':
                return 'light-gray';
            default:
                return '';
        }
    };

    const getNewsDataList = async () => {
        try {
          setIsLoading(true);
            // console.log({id});
            // alert(selectCurrency);
          const response = await apiCall(API_URL +'get-news', {
              user_id: state.userData.id,
              token: token,
              filter:isFilter,
              day:datesArray,
              impact: activeButtons,
              currency_id: selectCurrency

          });
          if (response.data.success == '0') {
              console.log('trading list history 1111111111111111111111',response.data.data);
              setNewsData(response.data.data);
            //   setTotalPagesTradingHistory(response.data.data.last_page)
          }
          setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const transformCurrencyData = () => {
        console.log('profitChartDataprofitChartDataprofitChartDataprofitChartData', currencyData);
        return currencyData.map(item => ({
            value: item.id,
            name: item.code
        }));
    };
    const CurrencyOptions = currencyData.length > 0 ? transformCurrencyData() : [];
    
    const handleDayChange = (day) => {
        setSelectedDays(prev => {
          if (prev.includes(day)) {
            // Remove day if already selected
            const updatedDays = prev.filter(d => d !== day);
            updateDatesArray(updatedDays);
            return updatedDays;
          } else {
            // Add day if not selected
            const updatedDays = [...prev, day];
            updateDatesArray(updatedDays);
            return updatedDays;
          }
        });
    };

    const updateDatesArray = (days) => {
      const dates = days.map(day => getDateForDayOfWeek(day));
      setDatesArray(dates);
    };
    
    const handleChangeCurrency = (event) => {
        setSelectCurrency(event.target.value)
        setIsFilter(1);
    }  

    const isPastDay = (date) => {
        const today = new Date();

         // Remove time from both dates
        const dateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        
        return dateWithoutTime < todayWithoutTime;
    };
      

  return (
    <>  
         {!dataLoaded && <Loaders/>}
    <div className="custome-wrapper">
        <Breadcrumb className="" breadcrumbIcon="CalendarSvg" breadcrumbHeading="Calendar" />
        <div className="container-lg cl-custome3">
            <div className="row row-gap-4">

                <div className="col-12">
                    <div className="common-box main_filter_section">
                        <div className="common-box-heading cbh-small"><Icons.FilterSvg />Filters</div>

                        <div className='row'>   
                            <div className='col-sm-5 col-md-4 col-lg-3 mb-1'> 
                                <div className='currency-filter'>
                                    <SelectInput heading="Currency" className="sgl-icon sgl-opacity-1"  options={CurrencyOptions} defaultOption="Select Currency" sglIcon=<Icons.CurrencyDollarSvg />  onChange={handleChangeCurrency} errorMeg="Country is required" />
                                </div>
                            </div> 
                            <div className='col-sm-7 col-md-8 col-lg-8 mb-2'>
                            <label>Days</label>
                                <div className="common-tabs-bx ctb-style-2 mt-1 mb-1">
                                    <div className={`ctb-item ${selectedDays.includes('mon') ? 'active' : ''}`} onClick={() => handleDayChange('mon')}>Monday</div>
                                    <div className={`ctb-item ${selectedDays.includes('tue') ? 'active' : ''}`} onClick={() => handleDayChange('tue')}>Tuesday</div>
                                    <div className={`ctb-item ${selectedDays.includes('wed') ? 'active' : ''}`} onClick={() => handleDayChange('wed')}>Wednesday</div>
                                    <div className={`ctb-item ${selectedDays.includes('thu') ? 'active' : ''}`} onClick={() => handleDayChange('thu')}>Thursday</div>
                                    <div className={`ctb-item ${selectedDays.includes('fri') ? 'active' : ''}`} onClick={() => handleDayChange('fri')}>Friday</div>
                                </div>
                            </div>  
                        </div>
                        <div className='row'>
                            <div className='col-md-7'>
                                <div className='fliter-tab'>
                                    <label>Impact</label>
                                    {/* <div className='filter-btn'>
                                        <button className={`btn btn-primary red ${activeButton == 1 ? 'inactive' : 'active'}`}  onClick={() => handleButtonClick(1,'high')}>High</button>
                                        <button className={`btn btn-secondary orange ${activeButton == 2 ? 'inactive' : 'active'}`}  onClick={() => handleButtonClick(2,'medium')}>Medium</button>
                                        <button className={`btn btn-success yellow ${activeButton == 3 ? 'inactive' : 'active'}`}  onClick={() => handleButtonClick(3,'low')}>Low</button>
                                        <button className={`btn btn-danger darkgray ${activeButton == 4 ? 'inactive' : 'active'}`}  onClick={() => handleButtonClick(4,'no_impact')}>NO impact</button>   
                                    </div> */}
                                    <div className='filter-btn'>
                                        <button
                                            className={`btn btn-primary red ${activeButtons.includes('high') ? 'active' : 'inactive'}`}
                                            onClick={() => handleButtonClick(1, 'high')}
                                        >
                                            High
                                        </button>
                                        <button
                                            className={`btn btn-secondary yellow ${activeButtons.includes('medium') ? 'active' : 'inactive'}`}
                                            onClick={() => handleButtonClick(2, 'medium')}
                                        >
                                            Medium
                                        </button>
                                        <button
                                            className={`btn btn-success green ${activeButtons.includes('low') ? 'active' : 'inactive'}`}
                                            onClick={() => handleButtonClick(3, 'low')}
                                        >
                                            Low
                                        </button>
                                        <button
                                            className={`btn btn-danger darkgray ${activeButtons.includes('no_impact') ? 'active' : 'inactive'}`}
                                            onClick={() => handleButtonClick(4, 'no_impact')}
                                        >
                                            NO impact
                                        </button>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>

                </div>    

                <div className="col-12">
                    <div className="common-box">
                        <div className="table-responsive custom-table-responsive calendar-table">
                        <table className="custom-table">
                            <thead>
                                <tr>
                                    <th>Currency</th>
                                    <th>Event Type</th>
                                    <th>Date</th>
                                    <th>Previous</th>
                                    <th>Forecast</th>
                                    <th></th>
                                </tr>
                            </thead>
                                {!isLoading && newsData && newsData.length > 0 &&
                                    <tbody>
                                        {!isLoading && newsData && newsData.length > 0 && (
                                            newsData.map((newdata, index) => (
                                                <React.Fragment key={index}>
                                                    <tr className={getRowClass(newdata.impact)} onClick={()=>handleCollaps(index)}>
                                                        <td>
                                                            <div className='currency'>
                                                                <span className='flag'><Icons.FlagSvg /></span>
                                                                <span className='c-big'>{newdata.news_currency.short_code}</span>
                                                                <span className='c-small'>{newdata.news_currency.code}</span>
                                                            </div>
                                                        </td>
                                                        <td>{newdata.event_type}</td>
                                                        <td>{newdata.news_date_format}{''}
                                                            {isPastDay((new Date(newdata.date))) && (<OverlayTrigger
                                                                delay={{ hide: 450, show: 300 }}
                                                                overlay={(props) => (
                                                                <Tooltip {...props}>
                                                                    This event has expired
                                                                </Tooltip>
                                                                )}
                                                                placement="top"
                                                            >
                                                            <span className='d-red ps-1'><Icons.ExclamationCircleSvg /></span>
                                                            </OverlayTrigger> )}
                                                        </td>
                                                        <td>{newdata.previous}</td>
                                                        <td>{newdata.forecast}</td>
                                                        <td className='text-center'><span><Icons.DownSvg /></span></td>
                                                    </tr>

                                                    <tr className={"common-box ct-accordation " + getRowClass(newdata.impact)+ (collapseAdd === index && collapseStatus === 'active' ? '' : ' d-none')}>
                                                        <td colspan="6">
                                                            <div className='cta-inner'>
                                                                <div className='cta-inner-detail'>
                                                                <div className='cta-box'>
                                                                        <div className='cta-box-title'>Actual</div>
                                                                        <div className='cta-box-text'>{newdata.actual}</div>
                                                                    </div>
                                                                    <div className='cta-box'>
                                                                        <div className='cta-box-title'>Previous</div>
                                                                        <div className='cta-box-text'>{newdata.previous}</div>
                                                                    </div>
                                                                    <div className='cta-box'>
                                                                        <div className='cta-box-title'>Forecast</div>
                                                                        <div className='cta-box-text'>{newdata.forecast}</div>
                                                                    </div>
                                                                </div>    

                                                                <div class="common-box-sub-heading">
                                                                    {newdata.description}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))
                                        )}
                                    </tbody>
                                }
                        </table>
                                
                        </div>
                        <div className="empty-box">
                            {(newsData.length <= 0) &&
                                <EmptyBox className="empty-div-bx" eh="Data is not found" esh="Try adjusting filters to get results." />
                            }
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
