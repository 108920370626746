import React from 'react'

export default function CheckInput({ className, name, id, label, onChange, checked = false, value = null }) {

  return (
    <div className={`signinup-group-checkmark ${className}`}>
      <input className="d-none" type="checkbox" name={name} id={id} onChange={onChange} value={value} checked={checked}/>
      <label className="checkmark-label" htmlFor={id}>
        <span className="checkmark-icon">
          <div className="cmi-inner"></div>
        </span>
        {label}
      </label>
    </div>
  )
}
