import React from 'react';
import {Link} from "react-router-dom";

// Icons
import Icons from '../../components/icons';

// Components
import Logo from '../../components/Logo';


export default function HeaderSignInUp({ to, onClick }) {
  return (
    <div className="signinup-screen-header">
      {(to || onClick) && 
      <Link to={to} onClick={onClick} className="SignInUpBackBtn">
        <Icons.LeftArrowSvg />
      </Link> 
      }
      <Logo className="signinup-logo-bx" />
    </div>
  )
}
