import React, { useState, useEffect } from 'react'
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';

// Common Field
import Input from '../../components/commonfield/Input'

// Images Common File
import HeaderSignInUp from './HeaderSignInUp';
import Logo from '../../components/Logo';
import * as Img from '../../components/Img';
import FlashMessage from '../../components/FlashMessage'; 

export default function PageForgot() {
  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { state } = useAuth();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    const loggedInUser = getLoggedInUserData();
    const isUserLoggedIn = !!loggedInUser;

    if (isUserLoggedIn) {
      navigate('/home')
    }
  }, [navigate,state.userData]);

  const handleEmailChange = (value) => {
    setEmail(value);
    setErrors((prev) => ({ ...prev, email: '' })); 
  };

  const validateFieldsStep = () => {
    const newErrors = {};
    if (!email) {
      newErrors.email = 'Email is required';
    } else if(email){
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        newErrors.email = 'Email is not valid';
      } 
    } 
    return newErrors;
  };
  // ---
  const handleSubmit = async() => {
    const valErrors = validateFieldsStep();
    if (Object.keys(valErrors).length === 0) {
      // Create a FormData object
      const formData = new FormData();
      formData.append('email',email);
      
      
      try {
        // Send FormData using Axios
        setIsLoading(true);
        setRequestSubmitSuccsess(false)
        setRequestSubmitError(false)
        apiCall(API_URL + 'send-forget-email', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
            if(response.data.success === 0){
              setEmail('');
              setRequestSubmitSuccsess(true)
            } else {
              setRequestSubmitError(true)
              setRequestSubmitErrorMsg(response.data.data.error)
            }
            setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
          setRequestSubmitError(true)
          setRequestSubmitErrorMsg(error.response.data.message)
          //console.error('Error uploading profile picture:', error);
        });
      } catch (error) {
        //console.error('Error uploading files:', error);
      }
    } else {
      // Set errors to state
      setErrors(valErrors);
    }
  };

  return (
    <>
    <section className="signinup-main-box">

        <HeaderSignInUp to="/" />

        <div className="signinup-screen">
            <Logo className="signinup-logo-bx" />
             
            <div className="signinup-header">
              <div className="screen-hed">Forgot Password</div>
              <div className="screen-pera">Please enter your email to help change your password.</div>
            </div>

            <form className="signinup-width">
              <div className="row">

                <div className="col-12">
                   <Input heading="Your email" type="email" placeholder="Enter email address" errorMeg={errors?.email} error={errors?.email} value={email} onChange={handleEmailChange}/>
                </div>

                <div className="col-12">
                  {!isLoading && <div className="common-submit-btn" onClick={handleSubmit}>Send Reset Link</div>
                  }
                  {isLoading && <div className="lsb-loader-btn">
                    <img src={Img.loading} alt="" />Please wait ....
                  </div>
                  }
                  {/* <div className="lsb-loader-btn"><img src={Img.loading} alt="" />Please wait ....</div> */}
                </div>

                <div className="col-12">
                    <div className="signinup-link-outer-bx slob-fdc fdc-two mb-0">
                      Back to 
                      <Link className="signinup-link-two" to="/">Sign in</Link>
                    </div>
                </div>

              </div>
            </form>
        </div>

    </section>
    <FlashMessage type="success" isVisible={requestSubmitSuccess} message="We have sent you password reset link in email" />
    <FlashMessage type="error" isVisible={requestSubmitError} message={requestSubmitErrorMsg} />
    </>
  )
}
