import React, { useState, useEffect } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';

// Context Files
import { useMenu } from '../../context/MenuContext';

// Icons & imgeas common files
import Icons from '../../components/icons'

// Css File
import "../../css/header.css"

// Components
import Logo from '../../components/Logo';
import Sidemenu from './Sidemenu';

export default function Header() {
  const [user, setUser] = useState('')
  const { state,dispatch } = useAuth();
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const navigate = useNavigate();
  // --- ---
  const { isActive, toggleMenu } = useMenu();
  // --- ---
  useEffect(() => {
    const loggedInUser = getLoggedInUserData();
    const isUserLoggedIn = !!loggedInUser;
    if (isUserLoggedIn) {
        setUserLoggedIn(true);
        setUser(loggedInUser);
    } else {
        navigate('/');
    }
}, [state.userData]);


const [isDarkMode, setIsDarkMode] = useState(() => {
  // Check local storage for the theme preference
  const savedTheme = localStorage.getItem('theme');
  return savedTheme === 'dark';
});

useEffect(() => {
  if (isDarkMode) {
    document.body.classList.add('darkmode');
    localStorage.setItem('theme', 'dark');
  } else {
    document.body.classList.remove('darkmode');
    localStorage.setItem('theme', 'light');
  }
}, [isDarkMode]);

const toggleTheme = () => {
  setIsDarkMode(!isDarkMode);
};

  return (
    <>
    <header className="custom-header">
        <div className="ch-inner">
          <div className="ch-left">
            <div className={`menu-icon-bx ${isActive ? 'active' : ''}`} onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <Logo className="ch-logo-bx" />
          </div>
          <div className="ch-right">
            <div className="theme-toggle">
              <button onClick={toggleTheme} className="mode-icon-box" title={isDarkMode ? "Light mode" : "Dark mode"}>
                {isDarkMode ? <Icons.SunSvg /> : <Icons.MoonSvg />}
              </button>
            </div>
          </div>
        </div>
    </header>

    <Sidemenu />
    </>
  )
}
