import React from 'react'

export default function ChallengeNotApproved({name, step, limit}) {
  return (
    <div className="col-12">
        <div className="challenge-card-bx notApproved">
            <div className="ccb-notactive-bx">
                <div className="ccb-notactive-inner">
                    Your request for challenge {name} {step} for account size ${limit} is under review
                </div>
            </div>
        </div>
    </div>  
  )
}
