import React from "react";
import MyRouter from "./routers/index";


function App() {
  return (
    <MyRouter />
  );
}

export default App;