import React, { useState, useEffect } from 'react';
import { Link,useLocation } from 'react-router-dom';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input'

import { useAuth, getLoggedInUserData } from '../../context/AuthContext';
// Common Field
import Input from '../../components/commonfield/Input';
import PasswordInput from '../../components/commonfield/PasswordInput';
import CheckInput from '../../components/commonfield/CheckInput';
import FlashMessage from '../../components/FlashMessage'; 

// Images Common File
import HeaderSignInUp from './HeaderSignInUp';
import Logo from '../../components/Logo';
import * as Img from '../../components/Img'

export default function PageSignUp() {
  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { state } = useAuth();
 
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitSuccessMsg, setRequestSubmitSuccessMsg] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [agree, setAgree] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ref = queryParams.get('ref');
    
  // --- Sign Up Tabbing Js Start
  const [step, setStep] = useState('step1');

  useEffect(() => {
    const loggedInUser = getLoggedInUserData();
    const isUserLoggedIn = !!loggedInUser;

    if (isUserLoggedIn) {
      navigate('/home')
    }
  }, [navigate,state.userData]);

  const handleStepClick = (stepId) => {
    if(stepId === 'step2'){
      const valErrors = validateFieldsStep1()
      if (Object.keys(valErrors).length === 0) {
        setStep(stepId);
      } else {
        setErrors(valErrors);
      }
    } else {
      setStep(stepId);
    }
  };

  const handleFirstNameChange = (value) => {
    setFirstName(value);
    setErrors((prev) => ({ ...prev, first_name: '' })); 
  };
  const handleLastNameChange = (value) => {
    setLastName(value);
    setErrors((prev) => ({ ...prev, last_name: '' })); 
  };
  const handleEmailChange = (value) => {
    setEmail(value);
    setErrors((prev) => ({ ...prev, email: '' })); 
  };
  const handleAgreeChange = (event) => {
    var agree = event.target.checked;
    if(agree){
        setErrors((prev) => ({ ...prev, agree: '' })); 
    }
    setAgree(agree)
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
    setErrors((prev) => ({ ...prev, password: '' })); 
  }; 

  const handleConfirmPasswordChange = (value) => {
    setConfirmPassword(value);
    setErrors((prev) => ({ ...prev, confirm_password: '' })); 
  }; 

  const validateFieldsStep1 = () => {
    const newErrors = {};
    if (!firstName) {
      newErrors.first_name = 'First name is required';
    }
    if (!lastName) {
      newErrors.last_name = 'Last name is required';
    }
    if (!email) {
      newErrors.email = 'Email is required';
    } else if(email){
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        newErrors.email = 'Email is not valid';
      } 
    }
    if (!phoneNumber) {
      newErrors.phoneNumber = 'Phone number is required';
    } else if (!/^\+(?:[0-9] ?){6,14}[0-9]$/.test(phoneNumber)) {
        newErrors.phoneNumber = 'Enter a valid phone number';
    }
    if (!agree) {
      newErrors.agree = 'Please agree on terms and conditions';
    } 
    return newErrors;
  };

  const validateFieldsStep2 = () => {
    const newErrors = {};
    if (!password) {
      newErrors.password = 'Password is required';
    } else if(password && password.length < 6){
      newErrors.password = 'Minimum 6 characters required';
    }
    if (!confirmPassword) {
      newErrors.confirm_password = 'Confirm password is required';
    } else if(confirmPassword.length < 6){
      newErrors.confirm_password = 'Minimum 6 characters required';
    } else if(password && confirmPassword){
      if(password !== confirmPassword){
        newErrors.confirm_password = 'Password and confirm password must match';
      }
    }
    return newErrors;
  };
  // ---
  const handleSubmit = async() => {
    const valErrors = validateFieldsStep2();
    if (Object.keys(valErrors).length === 0) {
      // Create a FormData object
      const formData = new FormData();
      formData.append('first_name',firstName);
      formData.append('last_name',lastName);
      formData.append('email',email);
      formData.append('phone',phoneNumber);
      formData.append('password',password);
      formData.append('confirm_password',confirmPassword);
      formData.append('ref',ref);
      try {
        // Send FormData using Axios
        setIsLoading(true);
        setRequestSubmitSuccsess(false)
        setRequestSubmitError(false)
        apiCall(API_URL + 'register', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
            if(response.data.success === 0){
              setFirstName('')
              setLastName('')
              setEmail('');
              setPassword('');
              setConfirmPassword('');
              setRequestSubmitSuccsess(true)
              setRequestSubmitSuccessMsg(response.data.message)
              setTimeout(() => {
                navigate('/');
              }, 2000);
            } else {
              setRequestSubmitError(true)
              setRequestSubmitErrorMsg(response.data.data.error)
            }
            setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
          setRequestSubmitError(true)
          setRequestSubmitErrorMsg(error.response.data.message)
          //console.error('Error uploading profile picture:', error);
        });
      } catch (error) {
        //console.error('Error uploading files:', error);
      } 
    } else {
      // Set errors to state
      setErrors(valErrors);
    }
  };
  // --- Sign Up Tabbing Js End

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    setErrors((prev) => ({ ...prev, phoneNumber: '' })); 
    
  };

  return (
    <>
    <section className="signinup-main-box">

        {step === "step1" ? (
          <HeaderSignInUp to="/" />
        ) : step === "step2" ? (
          <HeaderSignInUp to="/" />
        ) : null}

        <div className={`signinup-screen ${step === 'step1' ? '' : 'd-none'} `} id="step1">

            <Logo className="signinup-logo-bx" />

            <div className="signinup-header">
              <div className="screen-hed">Create an account</div>
              {/* <div className="screen-pera">to access your account</div> */}
            </div>

            <form className="signinup-width">
              <div className="row">

                <div className="col-6">
                   <Input heading="Your first name" type="text" placeholder="Enter first name" errorMeg={errors?.first_name} error={errors?.first_name} value={firstName} onChange={handleFirstNameChange}/>
                </div>

                <div className="col-6">
                   <Input heading="Your last name" type="text" placeholder="Enter last name" errorMeg={errors?.last_name} error={errors?.last_name} value={lastName} onChange={handleLastNameChange}/>
                </div>

                <div className="col-12">
                   <Input heading="Your email" type="email" placeholder="Enter email address" errorMeg={errors?.email} error={errors?.email} onChange={handleEmailChange} value={email}/>
                </div>

                <div className="col-12">
                    <div class="sgr-outer-label">Phone number</div>
                    <div className='signinup-group sg-country-code'>
                      
                      <PhoneInput
                        heading="Phone number"
                        international
                        placeholder="Enter phone number"
                        // errorMeg={errors?.phoneNumber} error={errors?.phoneNumber}
                        value={phoneNumber}
                        defaultCountry="IN"
                        onChange={handlePhoneNumberChange}/>
                        {errors?.phoneNumber && <div className='form-error-msg'>{errors?.phoneNumber}</div>}
                      </div>

                  </div>
             



                <div className="col-12">
                  <div className="signinup-link-outer-bx">
                    <CheckInput className="sgc-ci-18px sgc-ci-hover-off mb-0" name="AgreementCheck" id="AgreementCheck" 
                    label={
                      <>
                        I certify that I am 18 years of age or older, agree to the{' '}
                        <Link to="https://fundedfirm.com/trading-rules" target='_blank'>Trading Rules.</Link>, and acknowledge the{' '}
                        <Link to="https://fundedfirm.com/terms-conditions" target='_blank'>Terms & Conditions</Link>.
                      </>
                    } onChange={handleAgreeChange} checked={agree ? true : false} value={agree}/> 
                    
                  </div>
                  {errors.agree && <span className="form-error-msg" style={{margin: "0",marginTop: "-18px",marginBottom: "10px"}}>{errors.agree}</span>}
                </div>

                <div className="col-12">
                  <div onClick={() => handleStepClick('step2')} className="common-submit-btn">Continue</div>
                  {/* <div className="lsb-loader-btn"><img src={Img.loading} alt="" />Please wait ....</div> */}
                </div>

                <div className="col-12">
                  <div className="signinup-link-outer-bx slob-fdc fdc-two mb-0">
                    Already have an account?
                    <Link className="signinup-link-two" to="/">Sign in</Link></div>
                </div>

              </div>
            </form>
        </div>

        <div className={`signinup-screen ${step === 'step2' ? '' : 'd-none'} `} id="step2">

            <Logo className="signinup-logo-bx" />

            <div className="signinup-header">
              <div className="screen-hed">Set a password</div>
              {/* <div className="screen-pera">to access your account</div> */}
            </div>

            <form className="signinup-width">
              <div className="row">

                
                <div className="col-12">
                   <PasswordInput heading="Password" type="password" placeholder="Enter password" errorMeg={errors?.password} error={errors?.password} onChange={handlePasswordChange} value={password}/>
                </div>

                <div className="col-12">
                   <PasswordInput heading="Confirm password" type="password" placeholder="Enter confirm password" errorMeg={errors?.confirm_password} error={errors?.confirm_password} onChange={handleConfirmPasswordChange} value={confirmPassword}/>
                </div>

                <div className="col-12">
                  {!isLoading && <div className="common-submit-btn" onClick={handleSubmit}>Submit</div>
                  }
                  {isLoading && <div className="lsb-loader-btn">
                    <img src={Img.loading} alt="" />Please wait ....
                  </div>
                  }
                </div>
       

                <div className="col-12">
                  <div className="signinup-link-outer-bx slob-fdc fdc-two mb-0">
                        Back to
                        <div onClick={() => handleStepClick('step1')} className="signinup-link-two">Previous</div>
                    </div>
                </div>

              </div>
            </form>
        </div>

    </section>
         <FlashMessage type="success" isVisible={requestSubmitSuccess} message={requestSubmitSuccessMsg} />
         <FlashMessage type="error" isVisible={requestSubmitError} message={requestSubmitErrorMsg} />
    </>
  )
}
